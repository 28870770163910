/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import { omit } from 'ramda';
import {
    EMPTY,
    Observable,
} from 'rxjs';
import {
    tap,
} from 'rxjs/operators';


import { PROJECTION_VIEW_MODES_DEFAULT_VALUES } from '~/app/core/constants/portfolio-projection';
import { MY_PORTFOLIO_UNIVERSE_ID } from '~/app/core/constants/portfolios.constants';
import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import {
    AddArbitrageAction,
    ChangeViewModeAction,
    DeleteAllocationAction,
    DeleteArbitrageAction,
    EnhancePortfolioAction,
    GetProjectionPortfolios,
    ParseAllocations,
    ParseCreatingPortfolio,
    ResetAction,
    ResetViewModeDisplayAction,
    SetViewModeDisplayValuesAction,
    StartProjectionAction,
    UpdateAllocationsAction,
    UpdateCreatingPortfolioAction,
    UpdateDistributionParamsAction,
    UpdateMarketEventParams,
    UpdatePortfoliosAction,
    UpdateProjectionParamsAction,
} from '~/app/core/state/simulation-projections/simulation-projections.actions';
import { ColorUtilsService } from '~/app/core/utils/color-utils/color-utils.service';
import { CompareElementType } from '~/app/shared/enums/compare-element-type.enum';
import { Frequency } from '~/app/shared/enums/frequency.enum';
import { GroupCompositionViewMode } from '~/app/shared/enums/group-composition-view-mode.enum';
import { ProjectionPeriodicMode } from '~/app/shared/enums/projection-periodic-mode.enum';
import { ProjectionViewModeValue } from '~/app/shared/enums/projection-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { EntityToLoad } from '~/app/shared/types/entity-to-load.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { ComparedElement } from '~/app/shared/types/projection/compared-element.type';
import { MarketEventParams } from '~/app/shared/types/projection/market-event-params.type';
import { PeriodicIncomeParams } from '~/app/shared/types/projection/periodic-income-params.type';
import { PeriodicIncomePercentageParams } from '~/app/shared/types/projection/periodic-income-percentage-params.type';
import { ProjectionAllocationWithSrri } from '~/app/shared/types/projection/projection-allocation-with-srri.type';
import { ProjectionArbitrage } from '~/app/shared/types/projection/projection-arbitrage.type';
import { ProjectionCreatingPortfolio } from '~/app/shared/types/projection/projection-creating-portfolio.type';
import { ProjectionDistributionParams } from '~/app/shared/types/projection/projection-distribution-params.type';
import { ProjectionParams } from '~/app/shared/types/projection/projection-params.type';
import { Projection } from '~/app/shared/types/projection/projection.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';

export interface SimulationProjectionsStateModel {
    distributionParams: ProjectionDistributionParams,
    draft: {
        comparedPortfolios: ComparedElement[],
        params: ProjectionParams,
    },
    active: {
        comparedPortfolios: ComparedElement[],
        params: ProjectionParams,
    },
    arbitrages: ProjectionArbitrage[],
    viewModeDisplay: {
        [key: string]: ProjectionViewModeValue[],
    },
    viewMode: ViewModeType,
    projectionLoading: boolean,
    projection: Projection | null,
}

const defaults = {
    distributionParams: {
        compositionViewMode: GroupCompositionViewMode.ASSET,
        arbitrageCompositionViewMode: GroupCompositionViewMode.ASSET,
    },
    draft: {
        params: {
            portfolioIds: [],
            allocations: [],
            creatingPortfolio: null,
            initialAmount: 0,
            periodicMode: ProjectionPeriodicMode.AMOUNT,
            periodicIncome: {
                amount: {
                    amount: 0,
                    frequency: Frequency.MONTHLY,
                },
                percentage: {
                    percent: 0,
                    amount: 0,
                    frequency: Frequency.MONTHLY,
                },
            },
            marketEvent: null,
            duration: 8,
        },
        comparedPortfolios: [],
    },
    active: {
        params: {
            portfolioIds: [],
            allocations: [],
            creatingPortfolio: null,
            initialAmount: 0,
            periodicMode: ProjectionPeriodicMode.AMOUNT,
            periodicIncome: {
                amount: {
                    amount: 0,
                    frequency: Frequency.MONTHLY,
                },
                percentage: {
                    percent: 0,
                    amount: 0,
                    frequency: Frequency.MONTHLY,
                },
            },
            marketEvent: null,
            duration: 5,
        },
        comparedPortfolios: [],
    },
    arbitrages: [],
    viewModeDisplay: {
        PRO: PROJECTION_VIEW_MODES_DEFAULT_VALUES.PRO,
        MAIN: PROJECTION_VIEW_MODES_DEFAULT_VALUES.MAIN,
        ADVANCED: PROJECTION_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
    },
    viewMode: ViewModeType.MAIN,
    projectionLoading: false,
    projection: null,
};

export function getPortfolioSearchParams(portfoliosIds: string[]) : SearchQueryBody {
    return {
        fields: 'name,calculatedSrri,calculatedVolatility,srri,currency,type,permissions',
        q: '',
        filters: [{
            property: 'id',
            values: portfoliosIds,
            exclude: false,
            union: true,
        }],
        sorts: [],
        portfolioUniverse: MY_PORTFOLIO_UNIVERSE_ID,
        subSegmentation: '',
        includeMetadata: false,
        size: 50,
        startAt: 0,
        requestId: '',
    };
}

export function formatPeriodicIncome(
    periodicMode: ProjectionPeriodicMode,
    amountIncome: PeriodicIncomeParams,
    percentageIncome: PeriodicIncomePercentageParams,
): PeriodicIncomeParams {
    const amount = periodicMode === ProjectionPeriodicMode.AMOUNT ? amountIncome.amount : percentageIncome.amount;
    const frequency = periodicMode === ProjectionPeriodicMode.AMOUNT ? amountIncome.frequency : percentageIncome.frequency;

    const rate = periodicMode === ProjectionPeriodicMode.AMOUNT ? 1 : (percentageIncome.percent / 100);

    const amountCalculated = amount * rate;

    return {
        amount: amountCalculated,
        frequency,
    };
}

@State<SimulationProjectionsStateModel>({
    name: 'simulationProjections',
    defaults,
})
@Injectable()
export class SimulationProjectionsState {
    constructor(
        private portfoliosService: PortfoliosService,
        private colorsUtilsService: ColorUtilsService,
    ) { }

    static isDisplayedInCurrentViewMode(viewModeValue: ProjectionViewModeValue) {
        // eslint-disable-next-line max-len
        return createSelector([SimulationProjectionsState], (state: SimulationProjectionsStateModel) => state.viewModeDisplay[state.viewMode].some((item) => item === viewModeValue));
    }

    @Selector()
    static getDraftComparedPortfolios(state: SimulationProjectionsStateModel): ComparedElement[] {
        return state.draft.comparedPortfolios;
    }

    @Selector()
    static getDraftComparedPortfoliosCount(state: SimulationProjectionsStateModel): number {
        return state.draft.comparedPortfolios.length ?? 0;
    }

    @Selector()
    static getProjection(state: SimulationProjectionsStateModel): Projection | null {
        return state.projection;
    }

    @Selector()
    static getDraftProjectionsParams(state: SimulationProjectionsStateModel): ProjectionParams {
        return state.draft.params;
    }

    @Selector()
    static getDraftProjectionsPortfolioIdsParams(state: SimulationProjectionsStateModel): EntityToLoad[] {
        return state.draft.params.portfolioIds;
    }

    @Selector()
    static getDraftProjectionsAllocationsParams(state: SimulationProjectionsStateModel): ProjectionAllocationWithSrri[] {
        return state.draft.params.allocations;
    }

    @Selector()
    static getActiveProjectionsParams(state: SimulationProjectionsStateModel): ProjectionParams {
        return state.active.params;
    }

    @Selector()
    static getDraftProjectionsCreatingPortfolioParams(state: SimulationProjectionsStateModel): ProjectionCreatingPortfolio {
        return state.draft.params.creatingPortfolio;
    }

    @Selector()
    static getDraftProjectionsMarketEventParams(state: SimulationProjectionsStateModel): MarketEventParams | null {
        return state.draft.params.marketEvent;
    }

    @Selector()
    static getDraftProjectionsMarketEventStartYear(state: SimulationProjectionsStateModel): number | undefined {
        return state.draft.params.marketEvent?.startYear;
    }

    @Selector()
    static getDraftProjectionsDurationParams(state: SimulationProjectionsStateModel): number {
        return state.draft.params.duration;
    }

    @Selector()
    static getActiveComparedPortfolios(state: SimulationProjectionsStateModel): ComparedElement[] {
        return state.active.comparedPortfolios;
    }

    @Selector()
    static getActiveProjectionsMarketEventParams(state: SimulationProjectionsStateModel): MarketEventParams | null {
        return state.active.params.marketEvent;
    }

    @Selector()
    static getActiveProjectionsDurationParams(state: SimulationProjectionsStateModel): number {
        return state.active.params.duration;
    }

    @Selector()
    static getProjectionsDistributionParams(state: SimulationProjectionsStateModel): ProjectionDistributionParams {
        return state.distributionParams;
    }

    @Selector()
    static getProjectionsCompositionViewModeParam(state: SimulationProjectionsStateModel): GroupCompositionViewMode {
        return state.distributionParams.compositionViewMode;
    }

    @Selector()
    static getProjectionsArbitrageCompositionViewModeParam(state: SimulationProjectionsStateModel): GroupCompositionViewMode {
        return state.distributionParams.arbitrageCompositionViewMode;
    }

    @Selector()
    static getViewModeDisplay(state: SimulationProjectionsStateModel) {
        return state.viewModeDisplay[state.viewMode];
    }

    @Selector()
    static getViewModeParam(state: SimulationProjectionsStateModel): ViewModeType {
        return state.viewMode;
    }

    @Selector()
    static getArbitrages(state: SimulationProjectionsStateModel): ProjectionArbitrage[] {
        return state.arbitrages;
    }

    @Selector()
    static getProjectionLoading(state: SimulationProjectionsStateModel): boolean {
        return state.projectionLoading;
    }

    @Action(GetProjectionPortfolios)
    getProjectionPortfolios({ getState, patchState }: StateContext<SimulationProjectionsStateModel>): Observable<unknown> {
        let state = getState();
        const portfoliosIds = state.draft.params.portfolioIds.map((ptf) => ptf.id.toString());

        if (portfoliosIds.length === 0) {
            patchState({
                draft: {
                    ...state.draft,
                    comparedPortfolios: state.draft.comparedPortfolios.reduce((acc: ComparedElement[], comparedPortfolio) => {
                        if (comparedPortfolio.isLock) {
                            acc.push(comparedPortfolio);
                        }
                        return acc;
                    }, []),
                },
            });
            return EMPTY;
        }

        return this.portfoliosService.search(getPortfolioSearchParams(portfoliosIds)).pipe(
            tap((result) => {
                state = getState();
                const comparedElementLocked = state.draft.comparedPortfolios.filter((comparedElement) => comparedElement.isLock);
                const colors = this.colorsUtilsService.getThemeColors(
                    result.values.length,
                    comparedElementLocked.length,
                );

                patchState({
                    draft: {
                        ...state.draft,
                        comparedPortfolios: [
                            ...comparedElementLocked,
                            ...result.values.map((portfolio, index) => {
                                const portfolioParam = state.draft.params.portfolioIds.find((ptf) => ptf.id === portfolio.id);
                                return {
                                    color: colors[index],
                                    type: CompareElementType.PORTFOLIO,
                                    portfolio: {
                                        ...portfolio,
                                        ...(portfolioParam?.srri
                                            ? { calculatedSrri: portfolioParam?.srri }
                                            : {}),
                                    },
                                };
                            }),
                        ],
                    },
                });
            }),
        );
    }

    @Action(ParseAllocations)
    parseAllocations({ getState, patchState }: StateContext<SimulationProjectionsStateModel>): Observable<unknown> {
        let state = getState();
        const allocationsIdToGet = state.draft.params.allocations.reduce((acc: string[], alloc) => {
            if (!state.draft.comparedPortfolios.find((comparedElement) => comparedElement.allocation?.id === alloc.id)) {
                acc.push(Math.abs(alloc.id).toString());
            }
            return acc;
        }, []);

        if (allocationsIdToGet.length === 0) {
            return EMPTY;
        }

        return this.portfoliosService.search(getPortfolioSearchParams(allocationsIdToGet)).pipe(
            tap((result) => {
                const colors = this.colorsUtilsService.getThemeColors(
                    result.values.length * 2,
                    state.draft.comparedPortfolios.length,
                );
                state = getState();
                patchState({
                    draft: {
                        ...state.draft,
                        comparedPortfolios: [
                            ...state.draft.comparedPortfolios,
                            ...result.values.reduce((acc: ComparedElement[], portfolio, index) => {
                                const allocation = state.draft.params.allocations.find((alloc) => Math.abs(alloc.id) === portfolio.id);
                                if (allocation) {
                                    acc.push({
                                        color: colors[2 * index],
                                        type: CompareElementType.CURRENT_PORTFOLIO,
                                        portfolio: {
                                            ...portfolio,
                                            calculatedSrri: allocation.calculatedSrri,
                                        },
                                        isLock: true,
                                    });
                                    acc.push({
                                        color: colors[2 * index + 1],
                                        type: CompareElementType.UPDATING_PORTFOLIO,
                                        portfolio: {
                                            ...portfolio,
                                            calculatedSrri: allocation.newCalculatedSrri,
                                        },
                                        isLock: true,
                                        allocation,
                                    });
                                }
                                return acc;
                            }, []),
                        ],
                    },
                });
            }),
        );
    }

    @Action(ParseCreatingPortfolio)
    parseCreatingPortfolio({ getState, patchState }: StateContext<SimulationProjectionsStateModel>) {
        const state = getState();
        const { creatingPortfolio } = state.draft.params;

        if (creatingPortfolio
            && !state.draft.comparedPortfolios.find((comparedElement) => comparedElement.allocation?.id === creatingPortfolio.allocation.id)) {
            const colors = this.colorsUtilsService.getThemeColors(
                creatingPortfolio.initialAllocation ? 2 : 1,
                state.draft.comparedPortfolios.length,
            );
            patchState({
                draft: {
                    ...state.draft,
                    comparedPortfolios: [
                        ...state.draft.comparedPortfolios,
                        ...(creatingPortfolio.initialAllocation ? [{
                            color: colors[1],
                            portfolio: creatingPortfolio.portfolio as Portfolio,
                            type: CompareElementType.INITIAL_NEW_PORTFOLIO,
                            isLock: true,
                            allocation: creatingPortfolio.initialAllocation,
                        }] : []),
                        {
                            color: colors[0],
                            portfolio: creatingPortfolio.portfolio as Portfolio,
                            type: CompareElementType.NEW_PORTFOLIO,
                            isLock: true,
                            allocation: creatingPortfolio.allocation,
                        },
                    ],
                },
            });
        }
    }

    @Action(UpdateAllocationsAction)
    updateAllocations({ getState, patchState, dispatch }: StateContext<SimulationProjectionsStateModel>, action: UpdateAllocationsAction) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                params: {
                    ...state.draft.params,
                    allocations: action.allocations,
                },
            },
        });

        dispatch(new ParseAllocations());
    }

    @Action(DeleteAllocationAction)
    deleteAllocation({ getState, patchState, dispatch }: StateContext<SimulationProjectionsStateModel>, action: DeleteAllocationAction) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                params: {
                    ...state.draft.params,
                    allocations: state.draft.params.allocations.filter((allocation) => allocation.id !== action.allocationId),
                },
                comparedPortfolios: [
                    ...state.draft.comparedPortfolios.filter((item) => item.portfolio.id !== Math.abs(action.allocationId)),
                ],
            },
            arbitrages: state.arbitrages.filter((arbitrage) => arbitrage.sourceId !== Math.abs(action.allocationId)),
        });

        dispatch(new ParseAllocations());
    }

    @Action(UpdatePortfoliosAction)
    updatePortfolios({ getState, patchState, dispatch }: StateContext<SimulationProjectionsStateModel>, action: UpdatePortfoliosAction) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                params: {
                    ...state.draft.params,
                    portfolioIds: action.portfolioIds,
                },
            },
        });

        dispatch(new GetProjectionPortfolios());
    }

    @Action(EnhancePortfolioAction)
    enhancePortfolio({ getState, patchState }: StateContext<SimulationProjectionsStateModel>, action: EnhancePortfolioAction): void {
        const state = getState();
        const foundEntity = state.draft.comparedPortfolios
            .find((entity) => entity.portfolio.id === action.portfolioId && [
                CompareElementType.CURRENT_PORTFOLIO,
                CompareElementType.PORTFOLIO].includes(entity.type));

        if (!foundEntity) {
            return;
        }

        const colors = this.colorsUtilsService.getThemeColors(state.draft.comparedPortfolios.length + 2, 0);

        const getProjectionParams = (stateValue: {
            comparedPortfolios: ComparedElement[],
            params: ProjectionParams,
        }) => ({
            ...stateValue,
            comparedPortfolios: [
                ...stateValue.comparedPortfolios.filter((item) => item.portfolio.id !== action.portfolioId),
                {
                    portfolio: foundEntity.portfolio,
                    type: CompareElementType.CURRENT_PORTFOLIO,
                    color: colors[0],
                    isLock: true,
                },
                {
                    portfolio: {
                        ...foundEntity.portfolio,
                        calculatedSrri: action.newAllocation.srri,
                    },
                    type: CompareElementType.UPDATING_PORTFOLIO,
                    color: colors[1],
                    isLock: true,
                    allocation: {
                        id: -action.portfolioId,
                        currency: action.newAllocation.currency,
                        shares: action.newAllocation.shares.map((share) => ({
                            shareId: share.shareId,
                            weight: share.weight ?? 0,
                            amount: share.amount ?? 0,
                        })),
                        cashes: action.newAllocation.cashes.map((cash) => ({
                            currency: cash.currency,
                            weight: cash.weight ?? 0,
                            amount: cash.amount ?? 0,
                        })),
                    },
                },
            ].map((item, index) => ({
                ...item,
                color: colors[index],
            })),
            params: {
                ...stateValue.params,
                portfolioIds: [
                    ...stateValue.params.portfolioIds.filter((portfolio) => portfolio.id !== action.portfolioId),
                ],
                allocations: [
                    ...stateValue.params.allocations.filter((item) => item.id !== -action.portfolioId),
                    {
                        id: -action.portfolioId,
                        calculatedSrri: action.newAllocation.srri,
                        currency: action.newAllocation.currency,
                        shares: action.newAllocation.shares.map((share) => ({
                            weight: share.weight ?? 0,
                            amount: share.amount ?? 0,
                            shareId: share.shareId,
                        })),
                        cashes: action.newAllocation.cashes.map((cash) => ({
                            weight: cash.weight ?? 0,
                            amount: cash.amount ?? 0,
                            currency: cash.currency,
                        })),
                    },
                ],
            },
        });

        patchState({
            draft: getProjectionParams(state.draft),
            active: getProjectionParams(state.active),
            projection: {
                ...state.projection,
                totalInvestment: state.projection?.totalInvestment ?? 0,
                projectionResults: [
                    ...(state.projection?.projectionResults || []).filter((projection) => projection.id !== action.newProjectionResult.id),
                    action.newProjectionResult,
                ],
            },
            arbitrages: [
                {
                    sourceId: action.portfolioId,
                    compareTo: null,
                },
            ],
        });
    }

    @Action(UpdateCreatingPortfolioAction)
    updateCreatingPortfolio({ getState, patchState, dispatch }: StateContext<SimulationProjectionsStateModel>, action: UpdateCreatingPortfolioAction) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                params: {
                    ...state.draft.params,
                    creatingPortfolio: {
                        portfolio: action.portfolio,
                        allocation: action.allocation,
                        ...(action.initialAllocation ? {
                            initialAllocation: action.initialAllocation,
                        } : {}),
                    },
                },
            },
        });

        dispatch(new ParseCreatingPortfolio());
    }

    @Action(UpdateProjectionParamsAction)
    updateProjectionParams({ getState, patchState }: StateContext<SimulationProjectionsStateModel>, action: UpdateProjectionParamsAction) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                params: {
                    ...state.draft.params,
                    duration: action.durationInYears,
                    initialAmount: action.initialAmount || 0,
                    periodicMode: action.periodicMode,
                    periodicIncome: {
                        amount: action.amountPeriodicIncome,
                        percentage: action.percentagePeriodicIncome,
                    },
                    ...(action.marketEventParam !== undefined ? {
                        marketEvent: action.marketEventParam,
                    } : {}),
                },
            },
        });
    }

    @Action(UpdateMarketEventParams)
    updateMarketEventParams({ getState, patchState }: StateContext<SimulationProjectionsStateModel>,
        action: UpdateMarketEventParams) {
        const state = getState();

        patchState({
            draft: {
                ...state.draft,
                params: {
                    ...state.draft.params,
                    marketEvent: action.marketEventParam,
                },
            },
        });
    }

    @Action(UpdateDistributionParamsAction)
    updateDistributionParams({ getState, patchState }: StateContext<SimulationProjectionsStateModel>, action: UpdateDistributionParamsAction) {
        const state = getState();
        patchState({
            distributionParams: {
                ...state.distributionParams,
                ...(action.compositionViewMode ? { compositionViewMode: action.compositionViewMode } : {}),
                ...(action.arbitrageCompositionViewMode ? { arbitrageCompositionViewMode: action.arbitrageCompositionViewMode } : {}),
            },
        });
    }

    @Action(StartProjectionAction)
    startProjection({ getState, patchState }: StateContext<SimulationProjectionsStateModel>) {
        let state = getState();
        if ((state.draft.params.initialAmount === 0 && formatPeriodicIncome(
            state.draft.params.periodicMode,
            state.draft.params.periodicIncome.amount,
            state.draft.params.periodicIncome.percentage,
        ).amount === 0)
            || state.draft.params.duration === 0
            || (!state.draft.params.portfolioIds.length && !state.draft.params.allocations.length && !state.draft.params.creatingPortfolio)) {
            patchState({
                projection: null,
                active: {
                    ...state.draft,
                },
            });
            return EMPTY;
        }
        patchState({
            projectionLoading: true,
        });
        return this.portfoliosService.projection({
            initialAmount: state.draft.params.initialAmount,
            portfolioIds: [
                ...state.draft.params.portfolioIds.map((ptf) => ptf.id),
                ...(state.draft.params.allocations && state.draft.params.allocations.length ? state.draft.params.allocations.map((a) => Math.abs(a.id)) : []),
            ],
            duration: (state.draft.params.duration * 12),
            allocations: [
                ...state.draft.params.allocations.map((alloc) => omit(['calculatedSrri'], alloc)),
                ...(state.draft.params.creatingPortfolio ? [
                    state.draft.params.creatingPortfolio.allocation,
                    ...(state.draft.params.creatingPortfolio.initialAllocation ? [state.draft.params.creatingPortfolio.initialAllocation] : []),
                ] : []),
            ],
            periodicIncome: formatPeriodicIncome(
                state.draft.params.periodicMode,
                state.draft.params.periodicIncome.amount,
                state.draft.params.periodicIncome.percentage,
            ),
            marketEvents: [
                ...(
                    state.draft.params.marketEvent
                        ? [{
                            id: state.draft.params.marketEvent.event.id,
                            // (startYear - 1) * 12 + 1 => Année 1 = 1 mois; Année 2 = 13 mois; Année 3 = 25 mois ...
                            startMonth: ((state.draft.params.marketEvent.startYear - 1) * 12) + 1,
                        }]
                        : []
                ),
            ],
        }).pipe(
            tap((projection: Projection) => {
                state = getState();
                patchState({
                    projection,
                    active: {
                        ...state.draft,
                    },
                    projectionLoading: false,
                });
            }),
        );
    }

    @Action(ResetAction)
    reset({ setState, getState }: StateContext<SimulationProjectionsStateModel>) {
        const state = getState();
        setState({
            ...defaults,
            viewMode: state.viewMode ? state.viewMode : defaults.viewMode,
            draft: {
                ...state.draft,
                comparedPortfolios: [],
                params: {
                    ...state.draft.params,
                    portfolioIds: [],
                    allocations: [],
                    creatingPortfolio: null,
                },
            },
        });
    }

    @Action(ChangeViewModeAction)
    changeViewMode({ patchState }: StateContext<SimulationProjectionsStateModel>, action: ChangeViewModeAction) {
        patchState({
            viewMode: action.viewMode,
        });
    }

    @Action(ResetViewModeDisplayAction)
    resetViewModeDisplay({ getState, patchState }: StateContext<SimulationProjectionsStateModel>) {
        const state = getState();

        const currentViewMode = state.viewMode;

        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                ...(
                    currentViewMode === ViewModeType.PRO
                        ? {
                            PRO: PROJECTION_VIEW_MODES_DEFAULT_VALUES.PRO,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.ADVANCED
                        ? {
                            ADVANCED: PROJECTION_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.MAIN
                        ? {
                            MAIN: PROJECTION_VIEW_MODES_DEFAULT_VALUES.MAIN,
                        }
                        : {}
                ),
            },
        });
    }

    @Action(SetViewModeDisplayValuesAction)
    setViewModeDisplayValuesAction({ getState, patchState }: StateContext<SimulationProjectionsStateModel>, action: SetViewModeDisplayValuesAction) {
        const state = getState();
        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                [state.viewMode]: action.display,
            },
        });
    }

    @Action(AddArbitrageAction)
    addArbitrage({ getState, patchState }: StateContext<SimulationProjectionsStateModel>, action: AddArbitrageAction) {
        const state = getState();
        patchState({
            arbitrages: [
                ...state.arbitrages,
                {
                    sourceId: action.sourceId,
                    compareTo: action.compareTo,
                },
            ],
        });
    }

    @Action(DeleteArbitrageAction)
    deleteArbitrage({ getState, patchState }: StateContext<SimulationProjectionsStateModel>, action: DeleteArbitrageAction) {
        const state = getState();
        patchState({
            arbitrages: state.arbitrages.filter((arbitrage) => arbitrage.sourceId !== action.sourceId && arbitrage.compareTo !== action.compareTo),
        });
    }
}
