import { Injectable } from '@angular/core';
import {
    Action,
    Selector,
    State,
    StateContext,
    StateOperator,
    Store,
} from '@ngxs/store';
import {
    clone,
    equals,
    indexBy,
    mergeRight,
    omit,
    pick,
    prop,
    reject,
} from 'ramda';
import { tap } from 'rxjs/operators';

import {
    CARD_RISK_INDICATORS_UID,
    PORTFOLIO_ALERT_PERF,
    PORTFOLIOS_DEFAULT_DISPLAY_VALUE,
    PORTFOLIOS_DEFAULT_SORT_OPTIONS,
    PORTFOLIOS_SEARCH_FIELDS,
} from '~/app/core/constants/portfolios.constants';
import { AlertsService } from '~/app/core/services/api/alerts/alerts.service';
import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import { RiskIndicatorsService } from '~/app/core/services/api/risk-indicators/risk-indicators.service';
import { HasPortfoliosAction } from '~/app/core/state/application/application-action/application.actions';
import {
    AddPortfolioListAction,
    ApplyDisplayValuesAction,
    ApplySortValuesAction,
    AutocompleteSearchAction,
    ChangeAlertIdsAction,
    ChangeCurrentPortfolioListAction,
    ChangePortfolioUniverseAction,
    ChangeSubSegmentationAction,
    ClearPortfoliosAction,
    ClosePortfolioListAction,
    DeletePortfoliosAction,
    GetPortfolioMetaDataAction,
    GetPortfolioUniversesAction,
    GetRiskIndicatorsAction,
    PostAlertAction,
    ResetAndSearchAction,
    ResetCurrentDisplayValuesAction,
    SaveDefaultDisplayValuesAction,
    SearchAction,
    SelectAllPortfoliosAction,
    SelectUnselectPortfolioAction,
    SetFiltersAction,
    SetPortfolioViewTypeAction,
    SetSelectedPortfoliosAction,
    UnselectAllPortfoliosAction,
} from '~/app/core/state/portfolios-list/portfolios-list.actions';
import { CardType } from '~/app/shared/enums/card-type.enum';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { ViewType } from '~/app/shared/enums/view-type.enum';
import { AdvancedFiltersUtilsService } from '~/app/shared/services/advanced-filters-utils/advanced-filters-utils.service';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';
import { SortsUtilsService } from '~/app/shared/services/sorts-utils/sorts-utils.service';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValueGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-value-generic.type';
import { AlertTypeRefs } from '~/app/shared/types/alert-type-refs.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { CollectionOfPortfolios } from '~/app/shared/types/portfolio/collection-of-portfolios.type';
import { PortfolioSelected } from '~/app/shared/types/portfolio/portfolio-selected.type';
import { PortfolioSubSegmentation } from '~/app/shared/types/portfolio/portfolio-subsegmentation.type';
import { PortfolioUniverse } from '~/app/shared/types/portfolio/portfolio-universe.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';
import { SortRequestValue } from '~/app/shared/types/sort-request-value.type';

export interface PortfoliosListModel {
    alertIds: number[],
    isLoading: boolean,
    indicatorsIsLoading: { [portfolioId: string]: boolean },
    currentSubSegmentation: number,
    currentUniverse: number,
    extraFilters: Array<AdvancedFilterGeneric>,
    filters: Array<AdvancedFilterGeneric>,
    filtersCount: number,
    query: string,
    selected: Array<PortfolioSelected>,
    portfolios: Omit<CollectionOfPortfolios<number>, 'metadata'>,
    viewType: ViewType,
    displayValues: DisplayValue[],
    sortValues: SortValue[],
}

export interface PortfoliosListStateModel {
    alerts: AlertTypeRefs[],
    currentList: string,
    facets: FacetFamily[],
    portfolios: { [key: number] : Portfolio },
    lists: { [key: string]: PortfoliosListModel },
    subSegmentations: PortfolioSubSegmentation[],
    universes: PortfolioUniverse[],
    defaultDisplayValues: DisplayValue[],
}

const defaults = {
    alerts: [],
    currentList: '',
    facets: [],
    portfolios: {},
    lists: {},
    subSegmentations: [],
    universes: [],
    defaultDisplayValues: PORTFOLIOS_DEFAULT_DISPLAY_VALUE,
};

function isLoading() : StateOperator<PortfoliosListStateModel> {
    return (state: Readonly<PortfoliosListStateModel>) => ({
        ...state,
        lists: {
            ...state.lists,
            [state.currentList]: {
                ...state.lists[state.currentList],
                isLoading: true,
            },
        },
    });
}

function updateCurrentList(update: { [key: string]: any }) : StateOperator<PortfoliosListStateModel> {
    return (state: Readonly<PortfoliosListStateModel>) => ({
        ...state,
        lists: {
            ...state.lists,
            [state.currentList]: {
                ...state.lists[state.currentList],
                ...update,
            },
        },
    });
}

function searchQueries(
    limit: number | null,
    state: PortfoliosListStateModel,
    filters: AdvancedFilterValueGeneric[],
    fields: string,
    sorts: SortRequestValue[],
    includeMetadata: boolean,
) {
    const { currentUniverse, currentSubSegmentation, portfolios, query } = state.lists[state.currentList];
    const subSegmentation = state.subSegmentations.find((item) => item.id === currentSubSegmentation);

    return {
        fields,
        q: query,
        filters,
        sorts,
        portfolioUniverse: currentUniverse,
        subSegmentation: subSegmentation ? subSegmentation.code : 'ALL',
        includeMetadata,
        startAt: portfolios.startAt,
        size: (limit && limit < 10) ? limit : 10,
        requestId: portfolios.requestId,
    };
}

@State<PortfoliosListStateModel>({
    name: 'portfoliosList',
    defaults,
})
@Injectable()
export class PortfoliosListState {
    constructor(
        private alertsService: AlertsService,
        private store: Store,
        private sortsUtilsService: SortsUtilsService,
        private portfoliosService: PortfoliosService,
        private advancedFiltersUtilsService: AdvancedFiltersUtilsService,
        private riskIndicatorsService: RiskIndicatorsService,
        private indicatorsUtilsService: IndicatorsUtilsService,
    ) {}

    @Selector()
    static getAlerts(state: PortfoliosListStateModel) {
        return state.alerts;
    }

    @Selector()
    static getAlertsIdsInCurrentPortfolioList(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].alertIds;
    }

    @Selector()
    static getAlertsInCurrentPortfolioList(state: PortfoliosListStateModel) {
        return state.alerts?.filter((alert) => state.lists[state.currentList].alertIds.includes(alert.id)) ?? [];
    }

    @Selector()
    static getAlertsCount(state: PortfoliosListStateModel) {
        return state.alerts.reduce((counter, alert) => counter + alert.count, 0);
    }

    @Selector()
    static getCurrentPortfolioListViewType(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].viewType;
    }

    @Selector()
    static getCurrentPortfolioCount(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].portfolios.size;
    }

    @Selector()
    static getCurrentPortfolioSelectedCount(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].selected.length;
    }

    @Selector()
    static getCurrentPortfolioSubSegmentation(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].currentSubSegmentation;
    }

    @Selector()
    static getPortfolioUniverses(state: PortfoliosListStateModel) {
        return Object.values(state.universes);
    }

    @Selector()
    static getCurrentPortfolioUniverse(state: PortfoliosListStateModel) {
        return state.universes.find((item) => item.id === state.lists[state.currentList].currentUniverse);
    }

    @Selector()
    static getDefaultAlertsIdsInCurrentSubSegmentation(state: PortfoliosListStateModel) {
        const subSeg = state.subSegmentations.find((seg) => seg.id === state.lists[state.currentList].currentSubSegmentation);

        return subSeg ? subSeg.defaultAlertIds : [];
    }

    @Selector()
    static getDisplayIndicatorPortfolio(state: PortfoliosListStateModel): DisplayValue[] {
        return state.lists[state.currentList].displayValues;
    }

    @Selector()
    static getDefaultDisplayValues(state: PortfoliosListStateModel) {
        return state.defaultDisplayValues;
    }

    @Selector()
    static getPortfolios(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].portfolios.values.map((r) => state.portfolios[r]).filter((r) => r);
    }

    @Selector()
    static getQuery(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].query;
    }

    @Selector()
    static getRequestIdOfPortfolios(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].portfolios.requestId;
    }

    @Selector()
    static getSelectedPortfolios(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].selected;
    }

    @Selector()
    static getSelectedPortfolioIds(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].selected.map((selectedPortfolio) => selectedPortfolio.id);
    }

    @Selector()
    static getSubSegmentationTabs(state: PortfoliosListStateModel) {
        return state.subSegmentations.map((item) => ({
            key: item.id,
            label: item.name,
            number: item.count,
            isRiskType: !!item.defaultAlertIds.length && !item.defaultAlertIds.includes(PORTFOLIO_ALERT_PERF),
        }));
    }

    @Selector()
    static isLoading(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].isLoading;
    }

    @Selector()
    static indicatorsIsLoading(state: PortfoliosListStateModel) {
        return state.lists[state.currentList].indicatorsIsLoading;
    }

    @Selector()
    static getCurrentSortValues(state: PortfoliosListStateModel): SortValue[] {
        return state.lists[state.currentList].sortValues;
    }

    @Selector()
    static getCurrentDisplayCount(state: PortfoliosListStateModel): number {
        return state.lists[state.currentList].displayValues.length;
    }

    @Selector()
    static getCurrentSortCount(state: PortfoliosListStateModel): number {
        return state.lists[state.currentList].sortValues.length;
    }

    @Selector()
    static isSearchDisabled(state: PortfoliosListStateModel) {
        const isSearchLoading = this.isLoading(state);
        const portfoliosLength = state.lists[state.currentList].portfolios.values.length;
        const { totalNumber } = state.lists[state.currentList].portfolios;

        return portfoliosLength >= totalNumber || isSearchLoading;
    }

    @Selector()
    static getFacets(state: PortfoliosListStateModel): FacetFamily[] {
        return state.facets;
    }

    @Selector()
    static getCurrentFilters(state: PortfoliosListStateModel): AdvancedFilterGeneric[] {
        return state.lists[state.currentList].filters;
    }

    @Selector()
    static getCurrentFiltersCount(state: PortfoliosListStateModel): number {
        return state.lists[state.currentList].filtersCount;
    }

    @Selector()
    static getCurrentList(state: PortfoliosListStateModel): string {
        return state.currentList;
    }

    @Action(AutocompleteSearchAction)
    autocompleteSearch({ setState }: StateContext<PortfoliosListStateModel>, action: AutocompleteSearchAction) {
        setState(updateCurrentList({
            query: action.q,
            sortValues: [],
            ...(
                action.resetFilters
                    ? {
                        filters: [],
                        filtersCount: 0,
                    } : {}),
            ...(
                action.resetSubsegmentations
                    ? {
                        currentSubSegmentation: 0,
                    }
                    : {}
            ),
        }));
    }

    @Action(AddPortfolioListAction)
    addPortfolioList({ patchState, getState }: StateContext<PortfoliosListStateModel>, action: AddPortfolioListAction) {
        const state = getState();
        const subSeg = state.subSegmentations.find((seg) => seg.id === 0);

        if (!state.lists[action.key] || action.overrideList) {
            patchState({
                currentList: action.key,
                lists: {
                    ...state.lists,
                    [action.key]: {
                        alertIds: subSeg ? subSeg.defaultAlertIds : [],
                        currentUniverse: 1,
                        currentSubSegmentation: 0,
                        isLoading: false,
                        indicatorsIsLoading: {},
                        sortValues: [],
                        displayValues: state.defaultDisplayValues,
                        query: '',
                        selected: [],
                        extraFilters: action.extraFilters,
                        filters: [],
                        filtersCount: 0,
                        viewType: ViewType.LIST,
                        portfolios: {
                            size: 10,
                            totalNumber: 0,
                            startAt: 0,
                            requestId: '',
                            values: [],
                        },
                    },
                },
            });
        } else {
            patchState({
                currentList: action.key,
            });
        }
    }

    @Action(ChangeAlertIdsAction)
    changeAlertIdsAction({ setState }: StateContext<PortfoliosListStateModel>, action: ChangeAlertIdsAction) {
        setState(updateCurrentList({ alertIds: action.alertIds }));
    }

    @Action(PostAlertAction)
    postAlertAction({ getState, patchState }: StateContext<PortfoliosListStateModel>, { action }: PostAlertAction) {
        const state = getState();
        const portfolio: Portfolio | null = action.portfolioId ? state.portfolios[action.portfolioId] : null;
        return this.alertsService.postAlertAction(action)
            .pipe(
                tap(() => {
                    if (portfolio) {
                        patchState({
                            portfolios: {
                                ...state.portfolios,
                                [action.portfolioId as number]: {
                                    ...portfolio,
                                    alerts: portfolio.alerts?.filter((alert) => alert.id !== action.alertId),
                                },
                            },
                        });
                    }
                }),
            );
    }

    @Action(ChangeCurrentPortfolioListAction)
    changeCurrentPortfolioList({ patchState }: StateContext<PortfoliosListStateModel>, action: ChangeCurrentPortfolioListAction) {
        patchState({ currentList: action.key });
    }

    @Action(ChangeSubSegmentationAction)
    changeSubSegmentation({ getState, setState }: StateContext<PortfoliosListStateModel>, action: ChangeSubSegmentationAction) {
        const state = getState();
        const subSeg = state.subSegmentations.find((seg) => seg.id === action.subSegmentationId);

        // Add display value of indicators with alert
        const alerts = state.alerts?.filter((alert) => subSeg?.defaultAlertIds.includes(alert.id)) ?? [];
        const alertRiskIndicators = this.indicatorsUtilsService.getListOfRiskIndicatorSelected(alerts);
        const displayIndicators = [
            ...state.lists[state.currentList].displayValues,
        ];
        alertRiskIndicators.forEach((alertRiskIndicator) => {
            if (!displayIndicators.find((indicator) => alertRiskIndicator.code === indicator.code
                && alertRiskIndicator.period === indicator.period)) {
                displayIndicators.push({
                    uid: CARD_RISK_INDICATORS_UID,
                    type: CardType.RISK_INDICATOR,
                    code: alertRiskIndicator.code,
                    period: alertRiskIndicator.period as Period,
                });
            }
        });

        setState(updateCurrentList({
            alertIds: subSeg ? subSeg.defaultAlertIds : [],
            currentSubSegmentation: action.subSegmentationId,
            displayValues: displayIndicators,
        }));
    }

    @Action(ClearPortfoliosAction)
    clearPortfolios({ getState, patchState }: StateContext<PortfoliosListStateModel>, action: ClearPortfoliosAction) {
        const state = getState();
        const rest = pick(action.portfolioIds, state.portfolios);

        patchState({ portfolios: { ...rest } });
    }

    @Action(ClosePortfolioListAction)
    closePortfolioList({ getState, patchState }: StateContext<PortfoliosListStateModel>, action: ClosePortfolioListAction) {
        const state = getState();

        const rest = omit([action.key], state.lists);

        patchState({ lists: rest });
    }

    @Action(GetPortfolioMetaDataAction)
    getPortfolioMetaData({ patchState }: StateContext<PortfoliosListStateModel>, action: GetPortfolioMetaDataAction) {
        return this.portfoliosService.getMetaData(action.refreshCache)
            .pipe(
                tap((metaData) => {
                    patchState({
                        alerts: metaData.alertTypeRefs,
                        facets: metaData.facets,
                        subSegmentations: metaData.subSegmentations,
                    });
                }),
            );
    }

    @Action(DeletePortfoliosAction)
    deletePortfolios({ dispatch }: StateContext<PortfoliosListStateModel>, action: DeletePortfoliosAction) {
        return this.portfoliosService.deletePortfolios(action.portfolioIds)
            .pipe(
                tap(() => {
                    dispatch(new HasPortfoliosAction(false));
                }),
            );
    }

    @Action(GetPortfolioUniversesAction)
    getPortfolioUniverses({ patchState }: StateContext<PortfoliosListStateModel>) {
        return this.portfoliosService.getUniverses()
            .pipe(
                tap((universes) => {
                    patchState({ universes: [...universes] });
                }),
            );
    }

    @Action(ChangePortfolioUniverseAction)
    changePortfolioUniverse({ setState }: StateContext<PortfoliosListStateModel>, action: ChangePortfolioUniverseAction) {
        setState(updateCurrentList({ currentUniverse: action.universeId }));
    }

    @Action(ResetAndSearchAction)
    resetAndSearch({ setState, dispatch }: StateContext<PortfoliosListStateModel>) {
        setState(updateCurrentList({
            portfolios: {
                size: 10, totalNumber: 0, startAt: 0, requestId: '', values: [],
            },
        }));

        dispatch(new SearchAction());
    }

    @Action(SearchAction, { cancelUncompleted: true })
    search({ getState, patchState, setState, dispatch }: StateContext<PortfoliosListStateModel>) {
        const currentState = getState();

        setState(isLoading());

        const { displayValues, sortValues, portfolios, filters, extraFilters, alertIds } = currentState.lists[currentState.currentList];
        const includeMetadata = !portfolios.requestId;

        const formatFilter = this.advancedFiltersUtilsService.formatFilters([...filters, ...(extraFilters ?? [])], false, false, currentState.facets);
        const fields = this.indicatorsUtilsService.createFieldsForRequest(displayValues, alertIds, PORTFOLIOS_SEARCH_FIELDS.join(','));
        const sorts = this.sortsUtilsService.createSortsForRequest(sortValues);

        return this.portfoliosService.search(searchQueries(10, currentState, formatFilter, fields, sorts, includeMetadata))
            .pipe(
                tap((results) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { metadata, ...rest } = results;
                    const state = getState();

                    patchState({
                        ...(includeMetadata ? {
                            facets: metadata.facets,
                            subSegmentations: metadata.subSegmentations,
                            alerts: metadata.alertTypeRefs,
                        } : {}),
                        portfolios: mergeRight(indexBy(prop('id'), results.values), state.portfolios),
                    });

                    setState(updateCurrentList({
                        isLoading: false,
                        indicatorsIsLoading: results.values.reduce((acc, current) => ({
                            ...acc,
                            [current.id]: true,
                        }), {} as ({ [key: number]: boolean })),
                        portfolios: {
                            ...rest,
                            startAt: rest.values[rest.values.length - 1] ? rest.values[rest.values.length - 1].id : 0,
                            values: [
                                ...currentState.lists[currentState.currentList].portfolios.values,
                                ...rest.values.map((portfolio) => portfolio.id),
                            ],
                        },
                    }));
                }),
                tap((results) => {
                    dispatch(new GetRiskIndicatorsAction(results.values.map((portfolio) => portfolio.id)));
                }),
            );
    }

    @Action(GetRiskIndicatorsAction)
    getRiskIndicatorsData({ getState, setState }: StateContext<PortfoliosListStateModel>, action: GetRiskIndicatorsAction) {
        let state = getState();

        const displayIndicator = state.lists[state.currentList].displayValues;
        const alertsSelected = state.alerts?.filter((alert) => state.lists[state.currentList].alertIds.includes(alert.id)) ?? [];
        const indicatorsParams = this.indicatorsUtilsService.createIndicatorsForRequests(displayIndicator, alertsSelected);

        return this.riskIndicatorsService.getRiskIndicatorsValuesForPortfolios(action.portfoliosId, indicatorsParams.defaultRiskIndicators)
            .pipe(
                tap((results) => {
                    state = getState();
                    const portfolios = clone(state.portfolios);

                    for (let i = 0; i < results.length; i += 1) {
                        if (results[i].type === EntityType.PORTFOLIO && portfolios[results[i].id]) {
                            portfolios[results[i].id].riskIndicators = results[i].riskIndicators;
                        }
                    }

                    setState({
                        ...state,
                        portfolios,
                        lists: {
                            ...state.lists,
                            [state.currentList]: {
                                ...state.lists[state.currentList],
                                indicatorsIsLoading: {},
                            },
                        },
                    });
                }),
            );
    }

    @Action(SelectAllPortfoliosAction)
    selectAllPortfolios({ getState, setState }: StateContext<PortfoliosListStateModel>) {
        const state = getState();

        const selectedPortfolios = state.lists[state.currentList].portfolios.values.map((id) => ({
            id: state.portfolios[id].id,
            name: state.portfolios[id].name,
        }));

        setState(updateCurrentList({ selected: [...new Set(selectedPortfolios)] }));
    }

    @Action(SetSelectedPortfoliosAction)
    setSelectedPortfolios({ setState }: StateContext<PortfoliosListStateModel>, action: SetSelectedPortfoliosAction) {
        setState(updateCurrentList({ selected: [
            ...action.selected,
        ] }));
    }

    @Action(SelectUnselectPortfolioAction)
    selectUnselectPortfolio({ getState, setState }: StateContext<PortfoliosListStateModel>, action: SelectUnselectPortfolioAction) {
        const state = getState();

        let selected: PortfolioSelected[] = [
            ...state.lists[state.currentList].selected,
        ];

        const index = selected.find((portfolioSelected) => portfolioSelected.id === action.id);

        if (!index && !action.single) {
            selected = [
                ...selected,
                {
                    id: state.portfolios[action.id].id,
                    name: state.portfolios[action.id].name,
                },
            ];
        } else if (!index && action.single) {
            selected = [
                {
                    id: state.portfolios[action.id].id,
                    name: state.portfolios[action.id].name,
                },
            ];
        } else if (index && action.single) {
            selected = [];
        } else {
            selected = selected.filter((item) => item.id !== action.id);
        }

        setState(updateCurrentList({ selected }));
    }

    @Action(SetPortfolioViewTypeAction)
    setPortfolioViewTypeAction({ setState }: StateContext<PortfoliosListStateModel>, action: SetPortfolioViewTypeAction) {
        setState(updateCurrentList({ viewType: action.viewType }));
    }

    @Action(UnselectAllPortfoliosAction)
    unselectAllPortfolios({ setState }: StateContext<PortfoliosListStateModel>) {
        setState(updateCurrentList({ selected: [] }));
    }

    @Action(ResetCurrentDisplayValuesAction)
    resetCurrentDisplayValues({ getState, setState, dispatch }: StateContext<PortfoliosListStateModel>, action: ResetCurrentDisplayValuesAction) {
        setState(updateCurrentList({ displayValues: getState().defaultDisplayValues }));
        if (action.resetSearch) {
            dispatch(new ResetAndSearchAction());
        }
    }

    @Action(SaveDefaultDisplayValuesAction)
    savedDisplayValues({ patchState }: StateContext<PortfoliosListStateModel>, action: SaveDefaultDisplayValuesAction) {
        patchState({ defaultDisplayValues: action.displayValues });
    }

    @Action(ApplyDisplayValuesAction)
    applyDisplayValues({ getState, setState }: StateContext<PortfoliosListStateModel>, action: ApplyDisplayValuesAction) {
        const state = getState();

        // Delete all sort value if indicator isn't displayed anymore
        const displayOptions = [
            ...action.displayValues,
            ...PORTFOLIOS_DEFAULT_SORT_OPTIONS.map((option) => option.value),
        ].map((value) => reject((val) => val === undefined, value));
        const sortValues = state.lists[state.currentList].sortValues.map((value) => reject((val) => val === undefined, value));
        const newSortValues = sortValues.filter((sortValue) => displayOptions.find((displayValue) => equals(omit(['direction', 'sortCode'], sortValue), displayValue)));

        setState(updateCurrentList({
            displayValues: action.displayValues,
            sortValues: newSortValues,
        }));
    }

    @Action(ApplySortValuesAction)
    applySortValues({ setState }: StateContext<PortfoliosListStateModel>, action: ApplySortValuesAction) {
        setState(updateCurrentList({ sortValues: action.sortValues }));
    }

    @Action(SetFiltersAction)
    setFilters({ setState }: StateContext<PortfoliosListStateModel>, action: SetFiltersAction) {
        setState(updateCurrentList({
            filters: action.filters,
            filtersCount: action.filters.reduce((acc, filter: AdvancedFilterGeneric) => {
                if (filter.value.ranges instanceof Array) {
                    return acc + filter.value.ranges.length;
                }
                if (filter.value.values) {
                    return filter.value.values.reduce((acc2) => acc2 + 1, acc);
                }
                if (filter.value.periods) {
                    return filter.value.periods.reduce((acc2) => acc2 + 1, acc);
                }
                if (filter.value.value !== undefined && filter.value.value !== null) {
                    return acc + 1;
                }
                if (((filter.value.min !== null && filter.value.min !== undefined && filter.value.min >= 0)
                    || (filter.value.max !== null && filter.value.max !== undefined && filter.value.max >= 0)) && filter.value.id !== '') {
                    return acc + 1;
                }
                if (((typeof filter.value.min === 'string' && filter.value.min !== '')
                    || (typeof filter.value.max === 'string' && filter.value.max !== '')) && filter.value.id !== '') {
                    return acc + 1;
                }
                if (filter.value.properties && filter.value.properties.length) return acc + filter.value.properties.length;
                return acc;
            }, 0),
        }));
    }
}
