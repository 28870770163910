// eslint-disable-next-line max-classes-per-file
import { GroupCompositionViewMode } from '~/app/shared/enums/group-composition-view-mode.enum';
import { ProjectionPeriodicMode } from '~/app/shared/enums/projection-periodic-mode.enum';
import { ProjectionViewModeValue } from '~/app/shared/enums/projection-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { EntityToLoad } from '~/app/shared/types/entity-to-load.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { AllocationResponse } from '~/app/shared/types/portfolios-enhancement/allocation-response.type';
import { MarketEventParams } from '~/app/shared/types/projection/market-event-params.type';
import { PeriodicIncomeParams } from '~/app/shared/types/projection/periodic-income-params.type';
import { PeriodicIncomePercentageParams } from '~/app/shared/types/projection/periodic-income-percentage-params.type';
import { ProjectionAllocationWithSrri } from '~/app/shared/types/projection/projection-allocation-with-srri.type';
import { ProjectionAllocation } from '~/app/shared/types/projection/projection-allocation.type';
import { ProjectionPortfolio } from '~/app/shared/types/projection/projection-portfolio.type';

export class ResetAction {
    static readonly type = '[SimulationProjection] Reset';
}

export class UpdateMarketEventParams {
    static readonly type = '[SimulationProjection] Update market event param';

    constructor(public marketEventParam: MarketEventParams | null) { }
}

export class UpdateDistributionParamsAction {
    static readonly type = '[SimulationProjection] Update distribution params';

    constructor(
        public compositionViewMode: GroupCompositionViewMode | null,
        public arbitrageCompositionViewMode: GroupCompositionViewMode | null,
    ) { }
}

export class UpdatePortfoliosAction {
    static readonly type = '[SimulationProjection] Update portfolios';

    constructor(public portfolioIds: EntityToLoad[]) { }
}

export class GetProjectionPortfolios {
    static readonly type = '[SimulationProjection] get data of compared portfolios';
}

export class UpdateAllocationsAction {
    static readonly type = '[SimulationProjection] update allocations of projection';

    constructor(public allocations: ProjectionAllocationWithSrri[]) { }
}

export class DeleteAllocationAction {
    static readonly type = '[SimulationProjection] delete allocation of projection';

    constructor(public allocationId: number) { }
}

export class ParseAllocations {
    static readonly type = '[SimulationProjection] get data of allocations (actual portfolio + new allocation)';
}

export class UpdateCreatingPortfolioAction {
    static readonly type = '[SimulationProjection] update creating portfolio of projection';

    constructor(public portfolio: CreatePortfolio, public allocation: ProjectionAllocation, public initialAllocation: ProjectionAllocation | undefined) { }
}

export class ParseCreatingPortfolio {
    static readonly type = '[SimulationProjection] create compared element data of creating portfolio';
}

export class UpdateProjectionParamsAction {
    static readonly type = '[SimulationProjection] Update simulation params';

    public constructor(
        public initialAmount: number,
        public periodicMode: ProjectionPeriodicMode,
        public amountPeriodicIncome: PeriodicIncomeParams,
        public percentagePeriodicIncome: PeriodicIncomePercentageParams,
        public durationInYears: number,
        public marketEventParam?: MarketEventParams | null,
    ) { }
}

export class StartProjectionAction {
    static readonly type = '[SimulationProjection] Start projection';
}

export class ChangeViewModeAction {
    static readonly type = '[SimulationProjection] Change view mode';

    constructor(public viewMode: ViewModeType) {}
}

export class ResetViewModeDisplayAction {
    static readonly type = '[SimulationProjection] Reset view mode display';
}

export class SetViewModeDisplayValuesAction {
    static readonly type = '[SimulationProjection] Set viewMode display values';

    constructor(public display: ProjectionViewModeValue[]) {}
}

export class EnhancePortfolioAction {
    static readonly type = '[SimulationProjection] Enhance portfolio';

    constructor(public portfolioId: number, public newAllocation: AllocationResponse, public newProjectionResult: ProjectionPortfolio) {}
}

export class AddArbitrageAction {
    static readonly type = '[SimulationProjection] add arbitrage';

    constructor(
        public sourceId: number,
        public compareTo: number | null = null,
    ) {}
}

export class DeleteArbitrageAction {
    static readonly type = '[SimulationProjection] delete arbitrage';

    constructor(
        public sourceId: number,
        public compareTo: number | null = null,
    ) {}
}
