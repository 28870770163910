// eslint-disable-next-line max-classes-per-file
import { AllocationChangeType } from '~/app/shared/enums/allocation-change-type.enum';
import { CreationPortfolioType } from '~/app/shared/enums/creation-portfolio-type.enum';
import { GroupCompositionViewMode } from '~/app/shared/enums/group-composition-view-mode.enum';
import { PortfolioType } from '~/app/shared/enums/portfolio-type.enum';
import { PortfolioViewModeValue } from '~/app/shared/enums/portfolio-view-mode-value.enum';
import { PortfoliosState } from '~/app/shared/enums/portfolios-state.enum';
import { PortfoliosTab } from '~/app/shared/enums/portfolios-tab.enum';
import { USER_JOURNEY } from '~/app/shared/enums/user-journey.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { AlertActionParam } from '~/app/shared/types/alert-action/alert-action-param.type';
import { AllocationGroupPrices } from '~/app/shared/types/allocation/allocation-group-prices.type';
import { CashAllocationToUpdate } from '~/app/shared/types/cash-allocation-to-update.type';
import { DocumentItem } from '~/app/shared/types/document-item.type';
import { ErrorType } from '~/app/shared/types/error.type';
import { Operation } from '~/app/shared/types/operation.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { PortfolioRequestUpdate } from '~/app/shared/types/portfolio/portfolio-request-update.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { ShareAllocationToUpdate } from '~/app/shared/types/share-allocation-to-update.type';
import { SrriRequest } from '~/app/shared/types/srri-request.type';
import { Video } from '~/app/shared/types/video.type';

export class ApplyOperationAction {
    static readonly type = '[PortfolioDetails] Apply operation';

    constructor(public operation: Operation<unknown>) { }
}

export class ChangeActivePortfolioDetailsAction {
    static readonly type = '[PortfolioDetails] Change active portfolio detail';

    constructor(public key: string) { }
}

export class ChangeCreationUserJourneyAction {
    static readonly type = '[PortfolioDetails] Change creation user journey';

    constructor(public key: USER_JOURNEY) { }
}

export class ChangeCreationTypeAction {
    static readonly type = '[PortfolioDetails] Change creation type';

    constructor(public creationType: CreationPortfolioType) { }
}

export class ChangePortfolioTypeAction {
    static readonly type = '[PortfolioDetails] Change portfolio type';

    constructor(public type: PortfolioType) { }
}

export class ChangePortfolioErrorAction {
    static readonly type = '[PortfolioDetails] Change portfolio error';

    constructor(public error: ErrorType) { }
}

export class ChangeTabAction {
    static readonly type = '[PortfolioDetails] Change tab';

    constructor(public tab: PortfoliosTab) { }
}

export class InitPortfolioAction {
    static readonly type = '[PortfolioDetails] Init portfolio with data';

    constructor(
        public data: CreatePortfolio,
        public returnUrl: string | null = null,
        public replaceUrl: boolean = true,
        public operationId: string | null = null,
    ) { }
}

export class ChangePortfolioFieldAction {
    static readonly type = '[PortfolioDetails] Change portfolio field';

    constructor(public key: string, public value: any) { }
}

export class ChangePortfolioDocumentsAction {
    static readonly type = '[PortfolioDetails] Change portfolio documents';

    constructor(public documents: DocumentItem[]) { }
}

export class ChangePortfolioVideosAction {
    static readonly type = '[PortfolioDetails] Change portfolio videos';

    constructor(public videos: Video[]) { }
}

export class GetPortfolioVideosAction {
    static readonly type = '[PortfolioDetails] Get portfolio videos';

    constructor(public portfolioId: number) { }
}

export class DeletePortfolioVideoAction {
    static readonly type = '[PortfolioDetails] Delete portfolio video';

    constructor(public portfolioId: number, public videoId: string) { }
}

export class GetPortfolioDocumentsAction {
    static readonly type = '[PortfolioDetails] Get portfolio documents';

    constructor(public portfolioId: number) { }
}

export class DeletePortfolioDocumentAction {
    static readonly type = '[PortfolioDetails] Delete portfolio document';

    constructor(public portfolioId: number, public documentId: string) { }
}

export type AutocompletePayload = {
    q?: string,
    reset?: boolean,
}

export class GetShareCategoriesAction {
    static readonly type = '[PortfolioDetails] Get share categories';

    constructor(public payload: AutocompletePayload = {}) {}
}

export class GetClientsAction {
    static readonly type = '[PortfolioDetails] Get clients';

    constructor(public payload: AutocompletePayload = {}) {}
}

export class AllocationChangeTypeAction {
    static readonly type = '[PortfolioDetails] Update allocationChangeType';

    constructor(public allocationChangeType: AllocationChangeType) { }
}

export class GetCurrentForexAction {
    static readonly type = '[PortfolioDetails] Get current forex';
}

export class GetAllocationSharesAction {
    static readonly type = '[PortfolioDetails] Get allocation shares';
}

export class GetRiskIndicatorsOfAllocationSharesAction {
    static readonly type = '[PortfolioDetails] Get risk indicators of current share details';
}

export class UpdateAllocationInStateAction {
    static readonly type = '[PortfolioDetails] Update allocation';

    constructor(
        public cashAllocations: CashAllocationToUpdate[],
        public shareAllocations: ShareAllocationToUpdate[],
    ) { }
}

export class UpdateAllocationsMap {
    static readonly type = '[PortfolioDetails] Update map allocations';

    constructor(
        public allocations: Map<string | number, AllocationGroupPrices>,
    ) { }
}

export class CreateCurrentPortfolioAction {
    static readonly type = '[PortfolioDetails] Create current portfolio';
}

export class CreateCurrentPortfolioSuccessAction {
    static readonly type = '[PortfolioDetails] Create current portfolio success';

    constructor(
        public portfolio: Portfolio,
    ) {}
}

export class ReinitAllocationAction {
    static readonly type = '[PortfolioDetails] Reinit allocation';

    constructor(public portfolioId: number) { }
}

export class UpdateAllocationAction {
    static readonly type = '[PortfolioDetails] Update allocation in DB';
}

export class UpdatePortfolioAction {
    static readonly type = '[PortfolioDetails] Update portfolio in DB';

    constructor(public fieldsChanged: Partial<PortfolioRequestUpdate>) { }
}

export class ResetPortfolioCreateForm {
    static readonly type = '[PortfolioDetails] Reset portfolio create form';
}

export class ChangePortfolioStateAction {
    static readonly type = '[PortfolioDetails] Change portfolio state';

    constructor(public portfolioState: PortfoliosState) { }
}

export class ResetAction {
    static readonly type = '[PortfolioDetails] Reset';
}

export class GetPortfolioAction {
    static readonly type = '[PortfolioDetails] Get portfolio';

    constructor(public portfolioId: number) { }
}

export class InitPortfolioDetailAction {
    static readonly type = '[PortfolioDetails] Get portfolio and init states';

    constructor(public portfolioId: number, public operation: Operation<unknown> | null = null) { }
}


export class GetPortfolioLastAllocationAction {
    static readonly type = '[PortfolioDetails] Get portfolio last allocation';

    constructor(public portfolioId: number) { }
}

export class PostAlertAction {
    static readonly type = '[PortfolioDetails] Post alert action';

    constructor(public action: AlertActionParam) {}
}

export class ChangeViewModeAction {
    static readonly type = '[PortfolioDetails] Change view mode';

    constructor(public viewMode: ViewModeType) {}
}

export class ResetViewModeDisplayAction {
    static readonly type = '[PortfolioDetails] Reset view mode display';
}

export class SetViewModeDisplayValuesAction {
    static readonly type = '[PortfolioDetails] Set viewMode display values';

    constructor(public display: PortfolioViewModeValue[]) {}
}

export class UpdateGlobalParams {
    static readonly type = '[PortfolioDetails] Update global params';

    constructor(public period: string, public endDate: string) {}
}

export class UpdateRiskIndicatorsViewModeParams {
    static readonly type = '[PortfolioDetails] Update risk indicators view mode param';

    constructor(public riskIndicatorsViewMode: ViewModeType) {}
}

export class UpdateCompositionViewModeParams {
    static readonly type = '[PortfolioDetails] Update composition view mode param';

    constructor(public compositionViewMode: GroupCompositionViewMode) {}
}

export class TestSRRIAction {
    static readonly type = '[PortfolioDetails] Test SRRI';

    constructor(public allocation: SrriRequest) {}
}

export class EnableTestSRRIAction {
    static readonly type = '[PortfolioDetails] Enable test SRRI';
}

export class RefreshCurrentCalculatedSRRIAction {
    static readonly type = '[PortfolioDetails] Refresh current calculated SRRI';
}
export class ResetReturnUrlAction {
    static readonly type = '[PortfolioDetails] Reset returnUrl';
}

export class OptimiseAllocationEffectAction {
    static readonly type = '[PortfolioDetails] Trigger optimisation of allocation';

    constructor(public operationId: string) {}
}
