import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EMPTY,
    from,
    Observable,
} from 'rxjs';
import {
    concatMap,
    expand,
    reduce,
} from 'rxjs/operators';

import {
    SearchUniverseShareSearchRequestModel,
} from '~/app/shared/types/api/search-universe/search-universe-share-search-request-model.type';
import { ShareSearchRequestModel } from '~/app/shared/types/api/shares/share-search-request-model.type';
import { Collection } from '~/app/shared/types/collection.type';
import { SearchUniverseUploadResult } from '~/app/shared/types/search/search-universe-upload-result.type';
import { SearchUniverse } from '~/app/shared/types/search/search-universe.type';

@Injectable({
    providedIn: 'root',
})
export class SearchUniversesService {
    constructor(private http: HttpClient) {}

    getAll(): Observable<Readonly<Collection<SearchUniverse>>> {
        let valuesLength: number = 0;

        return this.getUniverses().pipe(
            expand((result) => {
                valuesLength += result.values.length;
                return valuesLength >= result.totalNumber ? EMPTY : this.getUniverses(result.values[result.values.length - 1]?.id || 0);
            }),
            reduce((acc: Readonly<Collection<SearchUniverse>>, val: Readonly<Collection<SearchUniverse>>) => ({
                ...acc,
                values: [...acc.values, ...val.values],
            }), {
                totalNumber: 0, startAt: 0, size: 0, values: [],
            }),
        );
    }

    // getUniverses(offset: number = 0, size: number = 50) {
    getUniverses(startAt: number = 0, size: number = 50) {
        return this.http.get<Readonly<Collection<SearchUniverse>>>('/search-universes',
            { params: {
                size, startAt,
            } });
    }

    getUniverseById(id: number) {
        return this.http.get<Readonly<SearchUniverse>>(`/search-universes/${id}`);
    }

    create(name: string) {
        return this.http.post<Readonly<SearchUniverse>>('/search-universes', { name });
    }

    update(id: number, name: string) {
        return this.http.put<Readonly<SearchUniverse>>(`/search-universes/${id}`, { name });
    }

    deleteUniverseById(id: number) {
        return this.http.delete(`/search-universes/${id}`);
    }

    addSharesByUniverseIdAndShareIds(id: number, shareIds: Array<number>) {
        return this.http.post(`/search-universes/${id}/shares`, { shareIds });
    }

    addSharesByUniversesIdsAndShareIds(ids: number[], sharesIds: number[]) {
        return from(ids).pipe(
            concatMap((id) => this.addSharesByUniverseIdAndShareIds(id, sharesIds)),
        );
    }

    addSharesByUniverseIdAndShareSearchRequest(id: number, shareSearch: SearchUniverseShareSearchRequestModel) {
        return this.http.post(`/search-universes/${id}/shares`, { shareSearch });
    }

    addSharesByUniversesIdsAndShareSearchRequest(ids: number[], shareSearch: SearchUniverseShareSearchRequestModel) {
        return from(ids).pipe(
            concatMap((id) => this.addSharesByUniverseIdAndShareSearchRequest(id, shareSearch)),
        );
    }

    deleteSharesByUniverseIdAndShareSearch(id: number, shareSearch: ShareSearchRequestModel) {
        return this.http.delete(`/search-universes/${id}/shares`, { body: { shareSearch } });
    }

    deleteSharesByUniverseIdAndShareIds(id: number, shareIds: Array<number>) {
        return this.http.delete(`/search-universes/${id}/shares`, { body: { shareIds } });
    }

    upload(file: File) {
        const formData = new FormData();

        formData.append('file', file);

        return this.http.post<Readonly<SearchUniverseUploadResult>>('/search-universes/upload', formData);
    }
}
