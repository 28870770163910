import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { Benchmark } from '~/app/shared/enums/benchmark.enum';
import { CardType } from '~/app/shared/enums/card-type.enum';
import { NotationCode } from '~/app/shared/enums/notation-code.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import {
    ActiveRiskSort,
    NotationSort,
    Period,
    RiskIndicatorSort,
} from '~/app/shared/interfaces/RiskIndicator';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { FacetValue } from '~/app/shared/types/facet/facet-value.type';
import { Facet } from '~/app/shared/types/facet/facet.type';
import { FieldOption } from '~/app/shared/types/field-option.type';
import { FieldValueEnum } from '~/app/shared/types/field-value-type.type';
import { AdvancedDisplayCardFamily } from '~/app/shared/types/sort-display/advanced-display-card-family.type';
import { AdvancedDisplayCardOption } from '~/app/shared/types/sort-display/advanced-display-card-option.type';


export function getFacetsByKey(facetFamilies: FacetFamily[]): { [key: string]: Facet } {
    return facetFamilies
        .reduce((acc, item) => [
            ...acc,
            ...item.values,
        ], [] as Facet[])
        .reduce((acc, item) => ({
            ...acc,
            [item.field]: item,
        }), {} as { [key: string]: Facet });
}

function getFieldOptionFromFacet(facet: FacetValue): FieldOption {
    return {
        code: facet.value || '',
        name: facet.name,
        translatable: false,
        sortable: true,
        type: FieldValueEnum.EXPO,
        isVisible: true,
    };
}

export function createLine(
    line: FieldOption,
    periods: Period[] = [],
): AdvancedDisplayCardOption {
    return {
        name: line.name,
        code: line.code,
        marketCycle: line.marketCycle,
        children: (periods || []).map((period) => ({
            code: period.code,
            name: period.name,
        })),
        translatable: line.translatable,
        isDefault: false,
        isLocked: false,
        isVisible: line.isVisible,
        explanationKey: line.code === RiskIndicatorCode.IART ? `explanation.risk.${line.code}` : '',
    };
}

export function createFacetCard(sourceId: string, name: string, lines: {[key:string]: FieldOption}): AdvancedDisplayCardFamily {
    return {
        name,
        uid: sourceId,
        type: CardType.FACET,
        options: Object.values(lines).map((line) => createLine(line, [])),
        isVisible: true,
    };
}

export function createExpoCard(sourceId: string, name: string, facetsFamilies: FacetFamily[], facetId: string): AdvancedDisplayCardFamily {
    const facetsByKey = getFacetsByKey(facetsFamilies);

    return {
        type: CardType.EXPO,
        name,
        uid: sourceId,
        field: facetId,
        isVisible: true,
        options: (facetsByKey[facetId]?.values || []).map(getFieldOptionFromFacet).map((line, index) => createLine({
            ...line,
            isVisible: index < 3,
        }, [])),
    };
}

export function createNotationCard(
    sourceId: string,
    name: string,
    defaultDisplayCodes: NotationCode[],
    notations: readonly NotationSort[],
    benchmark: Benchmark,
): AdvancedDisplayCardFamily {
    return {
        type: CardType.NOTATION,
        uid: sourceId,
        name,
        benchmark,
        options: (notations || []).map((line) => createLine({
            code: line.code,
            name: line.name,
            sortable: true,
            translatable: false,
            marketCycle: line.marketCycle.code,
            isVisible: defaultDisplayCodes.includes(line.code as NotationCode),
        }, line.periods)),
        isVisible: true,
    };
}

export function createRiskIndicatorCard(
    sourceId: string, name: string,
    defaultDisplayCodes: RiskIndicatorCode[],
    riskIndicators: readonly RiskIndicatorSort[],
): AdvancedDisplayCardFamily {
    return {
        type: CardType.RISK_INDICATOR,
        uid: sourceId,
        name,
        isVisible: true,
        options: (riskIndicators || [])
            .map((line) => createLine({
                code: line.code,
                name: line.name,
                sortable: true,
                translatable: false,
                isVisible: defaultDisplayCodes.includes(line.code as RiskIndicatorCode),
            }, line.periods)),
    };
}

export function createActiveRiskCard(
    sourceId: string,
    name: string,
    defaultDisplayCodes: ActiveRiskCode[],
    activeRisks: readonly ActiveRiskSort[],
    benchmark: Benchmark,
): AdvancedDisplayCardFamily {
    return {
        type: CardType.ACTIVE_RISK,
        uid: sourceId,
        name,
        benchmark,
        options: (activeRisks || []).map((line) => createLine({
            code: line.code,
            name: line.name,
            sortable: true,
            translatable: false,
            isVisible: defaultDisplayCodes.includes(line.code as ActiveRiskCode),
        }, line.periods)),
        isVisible: true,
    };
}
