import { uid } from 'uid';

import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { Benchmark } from '~/app/shared/enums/benchmark.enum';
import { CardType } from '~/app/shared/enums/card-type.enum';
import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { MarketCycleCode } from '~/app/shared/enums/market-cycle-code.enum';
import { NotationCode } from '~/app/shared/enums/notation-code.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { FieldOption } from '~/app/shared/types/field-option.type';
import { FieldValueEnum } from '~/app/shared/types/field-value-type.type';
import { ShareSearchSuggestionLabel } from '~/app/shared/types/shares/share-search-suggestion.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortOption } from '~/app/shared/types/sort-display/sort-option.type';

export const SHARES_SEARCH_FIELDS = [
    'assetManagerCompany',
    'assetUniverse',
    'assuranceVies',
    'category',
    'categoryId',
    'currency',
    'documents',
    'haveSimilarShares',
    'investGeography',
    'investSector',
    'isAlive',
    'isGreenfin',
    'isPartner',
    'isPea',
    'isSfdrArticle8',
    'isSfdrArticle9',
    'isSri',
    'isTracker',
    'isin',
    'legalForm',
    'name,',
    'price',
    'srri',
    'srriCalculated',
    'subCategory',
    'subCategoryId',
    'videos',
];

export const SHARES_SEARCH_SIMULATION_FIELDS = [
    'isAlive',
    'categoryId',
    'subCategoryId',
    'srri',
    'srriCalculated',
];


export const SHARES_DEFAULT_FILTERS = (currency: string): Array<AdvancedFilterGeneric> => [
    {
        uid: uid(),
        name: 'Part active',
        type: FacetType.BOOLEAN,
        value: {
            property: 'isAlive',
            value: true,
        },
    },
    {
        uid: uid(),
        name: 'Devise de la part',
        type: FacetType.MULTI_CHOICE,
        value: {
            property: 'currency',
            exclude: false,
            union: true,
            values: [
                `currency|${currency}`,
            ],
        },
        extra: {
            include: true,
            intersect: false,
        },
    },
    {
        uid: uid(),
        name: 'Effet de levier',
        type: FacetType.BOOLEAN,
        value: {
            property: 'isLeverage',
            value: false,
        },
    },
    {
        uid: uid(),
        name: 'Court',
        type: FacetType.BOOLEAN,
        value: {
            property: 'isShort',
            value: false,
        },
    },
];

export const SHARES_DEFAULT_OPTIONS: {[key:string]: FieldOption} = {
    srri: {
        code: 'srri',
        name: 'shares_list.sort.fields.default.srri',
        type: FieldValueEnum.RISK,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
};

export const SHARES_OTHER_OPTIONS: {[key:string]: FieldOption} = {
    assetUniverse: {
        code: 'assetUniverse',
        name: 'shares_list.sort.fields.other.assetUniverse',
        type: FieldValueEnum.TEXT,
        sortable: true,
        translatable: true,
        isVisible: false,
    },
    aum: {
        code: 'aum',
        name: 'shares_list.sort.fields.other.aum',
        type: FieldValueEnum.NUMBER,
        sortable: true,
        translatable: true,
        isVisible: false,
    },
    decisionMethod: {
        code: 'decisionMethod',
        name: 'shares_list.sort.fields.other.decisionMethod',
        type: FieldValueEnum.TEXT,
        sortable: true,
        translatable: true,
        isVisible: false,
    },
    fundCurrency: {
        code: 'fundCurrency',
        name: 'shares_list.sort.fields.other.fundCurrency',
        type: FieldValueEnum.OBJECT,
        sortable: true,
        translatable: true,
        isVisible: false,
    },

    investGeography: {
        code: 'investGeography',
        name: 'shares_list.sort.fields.other.investGeography',
        type: FieldValueEnum.OBJECT,
        sortable: true,
        translatable: true,
        isVisible: false,
    },
    investSector: {
        code: 'investSector',
        name: 'shares_list.sort.fields.other.investSector',
        type: FieldValueEnum.TEXT,
        sortable: true,
        translatable: true,
        isVisible: false,
    },
    distributionPolicy: {
        code: 'distributionPolicy',
        name: 'shares_list.sort.fields.other.priceType',
        type: FieldValueEnum.TEXT,
        sortable: true,
        translatable: true,
        isVisible: true,
    },
    launchDate: {
        code: 'launchDate',
        name: 'shares_list.sort.fields.other.launchDate',
        type: FieldValueEnum.DATE,
        sortable: true,
        translatable: true,
        isVisible: false,
    },
    isLeverage: {
        code: 'isLeverage',
        name: 'shares_list.sort.fields.other.isLeverage',
        sortable: false,
        type: FieldValueEnum.BOOLEAN,
        translatable: true,
        isVisible: false,
    },
    isPea: {
        code: 'isPea',
        name: 'shares_list.sort.fields.other.isPea',
        sortable: false,
        type: FieldValueEnum.BOOLEAN,
        translatable: true,
        isVisible: false,
    },
    isUcits: {
        code: 'isUcits',
        name: 'shares_list.sort.fields.other.isUcits',
        sortable: false,
        type: FieldValueEnum.BOOLEAN,
        translatable: true,
        isVisible: false,
    },
};

export const SHARES_FEES_OPTIONS: {[key:string]: FieldOption} = {
    managementFee: {
        code: 'managementFee',
        name: 'shares_list.sort.fields.fees.managementFee',
        type: FieldValueEnum.PERCENTAGE,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    exitFee: {
        code: 'exitFee',
        name: 'shares_list.sort.fields.fees.exitFee',
        type: FieldValueEnum.PERCENTAGE,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    extryFee: {
        code: 'extryFee',
        name: 'shares_list.sort.fields.fees.extryFee',
        type: FieldValueEnum.PERCENTAGE,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    ongoingChargeFee: {
        code: 'ongoingChargeFee',
        name: 'shares_list.sort.fields.fees.ongoingChargeFee',
        type: FieldValueEnum.PERCENTAGE,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
};

export const CARD_DEFAULT_UID = 'DEFAULT';
export const CARD_NOTATIONS_CATEGORY_UID = 'NOTATION_CATEGORY';
export const CARD_NOTATIONS_SUBCATEGORY_UID = 'NOTATION_SUBCATEGORY';
export const CARD_RISK_INDICATORS_UID = 'RISK_INDICATOR';
export const CARD_ACTIVE_RISKS_CATEGORY_UID = 'ACTIVE_RISK_CATEGORY';
export const CARD_ACTIVE_RISKS_SUBCATEGORY_UID = 'ACTIVE_RISK_SUBCATEGORY';
export const CARD_OTHERS_UID = 'OTHER';
export const CARD_FEES_UID = 'FEES';

export const SHARES_DEFAULT_SORT_OPTIONS: SortOption[] = [
    {
        name: 'shares_list.sort.fields.default.name',
        type: CardType.FACET,
        sortCode: 'name',
        value: {
            uid: CARD_DEFAULT_UID,
            type: CardType.FACET,
            code: 'name',
        },
    },
];

export const SHARES_DEFAULT_INDICATORS_VALUE: DisplayValue[] = [
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.PYTD,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P03M,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P06M,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P01Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.VOLT,
        period: Period.P01Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P03Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.VOLT,
        period: Period.P03Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P05Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.VOLT,
        period: Period.P05Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P10Y,
    },
];

export const SHARES_DEFAULT_NOTATIONS_VALUE: DisplayValue[] = [
    {
        uid: CARD_NOTATIONS_CATEGORY_UID,
        type: CardType.NOTATION,
        code: NotationCode.NSP,
        marketCycle: MarketCycleCode.OV,
        benchmark: Benchmark.BCAT,
        period: Period.P03Y,
    },
    {
        uid: CARD_NOTATIONS_CATEGORY_UID,
        type: CardType.NOTATION,
        code: NotationCode.NSP,
        marketCycle: MarketCycleCode.OV,
        benchmark: Benchmark.BCAT,
        period: Period.P05Y,
    },
    {
        uid: CARD_NOTATIONS_CATEGORY_UID,
        type: CardType.NOTATION,
        code: NotationCode.NST,
        marketCycle: MarketCycleCode.ST,
        benchmark: Benchmark.BCAT,
    },
    {
        uid: CARD_NOTATIONS_CATEGORY_UID,
        type: CardType.NOTATION,
        code: NotationCode.NCR,
        marketCycle: MarketCycleCode.CR,
        benchmark: Benchmark.BCAT,
    },
];

export const SHARES_DEFAULT_DISPLAY_VALUE: DisplayValue[] = [
    {
        uid: CARD_DEFAULT_UID,
        type: CardType.FACET,
        code: 'srri',
    },
    ...SHARES_DEFAULT_NOTATIONS_VALUE,
    ...SHARES_DEFAULT_INDICATORS_VALUE,
];

export const TOP_SHARES_DEFAULT_DISPLAY_VALUE: DisplayValue[] = [
    {
        uid: CARD_DEFAULT_UID,
        type: CardType.FACET,
        code: 'srri',
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P01Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P02Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P03Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P04Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P05Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P06Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P07Y,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.IART,
        period: Period.P08Y,
    },
];

export const SHARES_DEFAULT_DISPLAY_VALUE_SHORTLIST_NOTATIONS = [
    NotationCode.NSP,
    NotationCode.NCR,
];

export const SHARES_DEFAULT_DISPLAY_VALUE_SHORTLIST_ACTIVE_RISKS = [
    ActiveRiskCode.EPEF,
    ActiveRiskCode.BETA,
];

export const SHARES_DEFAULT_DISPLAY_VALUE_SHORTLIST_RISK_INDICATORS = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.SHRT,
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.IART,
];

export const SHARES_SUGGESTIONS: ShareSearchSuggestionLabel = {
    isin: 'common.suggestions.isin',
    name: 'common.suggestions.share_names',
};

export const ENVESTBOARD_UNIVERSE_ID = 0;

export const SHARE_EXPORT_LIMIT = 3000;

export const SHARES_EXPORT_FIELDS = [
    'isin',
    'name',
    'assetManagerCompany',
    'category',
    'subCategory',
    'assetUniverse',
    'currency',
    'isTracker',
    'price',
    'srri',
    'srriCalculated',
    'isSri',
    'isGreenfin',
    'isSfdrArticle8',
    'isSfdrArticle9',
];
