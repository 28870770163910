import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    catchError,
    Observable,
    of,
} from 'rxjs';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import {
    Notation,
    NotationSort,
    NotationValueRequest,
    NotationValueResponse,
} from '~/app/shared/interfaces/RiskIndicator';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';

@Injectable({
    providedIn: 'root',
})
export class NotationsService {
    constructor(
        private http: HttpClient,
        private indicatorsUtilsService: IndicatorsUtilsService,
        private authFacade: AuthenticationFacade,
        private authorisationFacade: AuthorisationFacade,
    ) {
    }

    getNotations(q?: string) {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        const plan = this.authorisationFacade.getPlanSnapshot();

        return this.getNotationsRaw(languageCode, plan?.id ?? null, q);
    }

    getNotationsValuesForSharesOrCategories(
        sharesId: Array<number>,
        categoriesId: Array<number>,
        notations: Array<NotationValueRequest>,
    ): Observable<ReadonlyArray<NotationValueResponse>> {
        if (notations.length === 0 || (sharesId.length === 0 && categoriesId.length === 0)) { return of([]); }
        const references = this.indicatorsUtilsService.createReferencesForRequest(sharesId, categoriesId);

        return this.http.post<ReadonlyArray<NotationValueResponse>>(
            '/notations/values',
            {
                references,
                notations,
            },
        ).pipe(
            catchError(() => {
                const refs: NotationValueResponse[] = references.map((reference) => ({
                    ...reference,
                    notations: [] as Array<Notation>,
                }));

                return of(refs);
            }),
        );
    }

    @Memoize({
        isObservable: true,
    })
    private getNotationsRaw(
        _languageCode: string, // keep for memoization
        _planId: number | null,
        q?: string,
    ) {
        return this.http.get<Readonly<NotationSort[]>>('/notations', {
            params: {
                ...(q ? { q } : {}),
            },
        });
    }
}
