// eslint-disable-next-line max-classes-per-file
import { ViewType } from '~/app/shared/enums/view-type.enum';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValue } from '~/app/shared/types/advanced-filter/advanced-filter-value.type';
import { AlertActionParam } from '~/app/shared/types/alert-action/alert-action-param.type';
import { PortfolioSelected } from '~/app/shared/types/portfolio/portfolio-selected.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';

export class AutocompleteSearchAction {
    static readonly type = '[PortfoliosList] Autocomplete search';

    constructor(
        public q: string,
        public resetFilters: boolean,
        public resetSubsegmentations: boolean,
    ) {}
}

export class AddPortfolioListAction {
    static readonly type = '[PortfoliosList] Add new portfolio list';

    constructor(public key: string, public overrideList = false, public extraFilters: AdvancedFilterGeneric[] = []) {}
}

export class ChangeAlertIdsAction {
    static readonly type = '[PortfoliosList] Change current alert ids';

    constructor(public alertIds: number[]) {}
}

export class ChangeCurrentPortfolioListAction {
    static readonly type = '[PortfoliosList] Change current portfolio list';

    constructor(public key: string) {}
}

export class ChangeSubSegmentationAction {
    static readonly type = '[PortfoliosList] Change current sub segmentation';

    constructor(public subSegmentationId: number) {}
}

export class ClearPortfoliosAction {
    static readonly type = '[PortfoliosList] Clear portfolios';

    constructor(public portfolioIds: Array<number> = []) {}
}

export class ClosePortfolioListAction {
    static readonly type = '[PortfoliosList] Close portfolio list';

    constructor(public key: string) {}
}

export class GetPortfolioMetaDataAction {
    static readonly type = '[PortfoliosList] Retrieve the portfolio metaData';

    public constructor(public refreshCache: boolean = false) {}
}

export class DeletePortfoliosAction {
    static readonly type = '[PortfoliosList] Delete portfolios';

    constructor(public portfolioIds: number[]) {}
}

export class GetPortfolioUniversesAction {
    static readonly type = '[PortfoliosList] Retrieve the portfolio universes';
}

export class ChangePortfolioUniverseAction {
    static readonly type = '[PortfoliosList] Change current portfolio universe';

    constructor(public universeId: number) {}
}

export class GetRiskIndicatorsAction {
    static readonly type = '[PortfoliosList] Get risk-indicators of portfolios';

    constructor(public portfoliosId: Array<number>) {}
}

export class PostAlertAction {
    static readonly type = '[PortfoliosList] Post alert action';

    constructor(public action: AlertActionParam) {}
}

export class ResetAndSearchAction {
    static readonly type = '[PortfoliosList] Reset and search portfolios';
}

export class SearchAction {
    static readonly type = '[PortfoliosList] Search portfolios';
}

export class SelectUnselectPortfolioAction {
    static readonly type = '[PortfoliosList] Select/Unselect portfolio by id';

    constructor(public id: number, public single = false) {}
}

export class SelectAllPortfoliosAction {
    static readonly type = '[PortfoliosList] Select all portfolios';
}

export class SetPortfolioViewTypeAction {
    static readonly type = '[PortfoliosList] Set View Type';

    constructor(public viewType: ViewType) {}
}

export class SetSelectedPortfoliosAction {
    static readonly type = '[PortfoliosList] Set selected portfolios';

    public constructor(
        public selected: PortfolioSelected[],
    ) {
    }
}
export class UnselectAllPortfoliosAction {
    static readonly type = '[PortfoliosList] Unselect all portfolios';
}


export class ResetCurrentDisplayValuesAction {
    static readonly type = '[PortfoliosList] reset current display values';

    constructor(public resetSearch: boolean = false) {}
}

export class SaveDefaultDisplayValuesAction {
    static readonly type = '[PortfoliosList] save default display values';

    constructor(public displayValues: DisplayValue[]) {}
}

export class ApplyDisplayValuesAction {
    static readonly type = '[PortfoliosList] apply display values';

    constructor(public displayValues: DisplayValue[]) {}
}

export class ApplySortValuesAction {
    static readonly type = '[PortfoliosList] apply sort values';

    constructor(public sortValues: SortValue[]) {}
}

export class SetFiltersAction {
    static readonly type = '[PortfoliosList] Set Filters';

    constructor(public filters: AdvancedFilterValue[]) {}
}
