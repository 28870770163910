import {
    inject,
    Injectable,
} from '@angular/core';

import { MarketCycleCode } from '~/app/shared/enums/market-cycle-code.enum';
import { BaseConfigurationService } from '~/app/shared/services/base-configuration/base-configuration.service';

const MIN_HUE = 0;
const MAX_HUE = 360;
const MIN_SAT = 75;
const MAX_SAT = 100;
const MIN_LIGHT = 65;
const MAX_LIGHT = 80;
const MIN_LIGHT_DARKER = 40;
const MAX_LIGHT_DARKER = 65;
const SCALE_LIGHT = 15;
const HUE_DIFFERENCE = 25;

@Injectable({
    providedIn: 'root',
})
export class ColorUtilsService {
    private threshold = 0.5;

    private configurationFacade = inject(BaseConfigurationService);

    public hexToRgb(hexadecimal: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexadecimal);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } : null;
    }

    public isDarkFontColorNecessary(backgroundColor: string) {
        const rgbColors = this.hexToRgb(backgroundColor);
        if (rgbColors) {
            const luma = ((0.2126 * rgbColors.r) + (0.7152 * rgbColors.g) + (0.0722 * rgbColors.b)) / 255;
            return luma > this.threshold;
        }
        return true;
    }

    public getThemeColors(length: number = 20, offset: number = 0): string[] {
        if (length < 1) {
            return [];
        }

        const normalizedThemeColors = Object.values(this.configurationFacade.getGraphColorsSnapshot() ?? [])
            .reduce((acc: string[], val) => (val !== null ? [...acc, val] : acc), []);

        const themeColors = [
            ...normalizedThemeColors,
            '#2ec7c9',
            '#b6a2de',
            '#5ab1ef',
            '#ffb980',
            '#d87a80',
            '#8d98b3',
            '#e5cf0d',
            '#97b552',
            '#95706d',
            '#dc69aa',
            '#07a2a4',
            '#9a7fd1',
            '#588dd5',
            '#f5994e',
            '#c05050',
            '#59678c',
            '#c9ab00',
            '#7eb00a',
            '#6f5553',
            '#c14089',
            '#c12e34',
            '#e6b600',
            '#0098d9',
            '#2b821d',
            '#005eaa',
            '#339ca8',
            '#cda819',
            '#32a487',
            '#c1232b',
            '#27727b',
            '#fcce10',
            '#e87c25',
            '#b5c334',
            '#fe8463',
            '#9bca63',
            '#fad860',
            '#f3a43b',
            '#60c0dd',
            '#d7504b',
            '#c6e579',
            '#f4e001',
            '#f0805a',
            '#26c0c0',
            '#e01f54',
            '#001852',
            '#f5e8c8',
            '#b8d2c7',
            '#c6b38e',
            '#a4d8c2',
            '#f3d999',
            '#d3758f',
            '#dcc392',
            '#2e4783',
            '#82b6e9',
            '#ff6347',
            '#a092f1',
            '#0a915d',
            '#eaf889',
            '#6699FF',
            '#ff6666',
            '#3cb371',
            '#d5b158',
            '#38b6b6',
            '#d87c7c',
            '#919e8b',
            '#d7ab82',
            '#6e7074',
            '#61a0a8',
            '#efa18d',
            '#787464',
            '#cc7e63',
            '#724e58',
            '#4b565b',
        ];

        return [
            ...themeColors.slice(offset, length + offset),
            // TODO: generate random color palette on last color of themeColors
            ...this.generateRandomColorPalette(length - themeColors.length + offset),
        ];
    }

    public generateRandomColor(excludeBrights: boolean = false) {
        let red: number | undefined;
        let green: number | undefined;
        let blue: number | undefined;

        let max = 255 * 3;

        if (excludeBrights) {
            max = 155 * 3;
        }

        while (!red || !green || !blue || red + green + blue > max) {
            red = Math.floor(Math.random() * 255);
            green = Math.floor(Math.random() * 255);
            blue = Math.floor(Math.random() * 255);
        }

        return this.rgbToHex(red, green, blue);
    }

    public generateRandomColorPalette(length: number, lightBackground: boolean = true): string[] {
        if (length < 1) {
            return [];
        }

        const range = [...Array(length).keys()];
        let baseColor = this.color(lightBackground);
        let maxVariationsCounter = 1;

        const colors = range.map(() => {
            if (maxVariationsCounter === 2) {
                baseColor = this.color(lightBackground);
                maxVariationsCounter = 1;

                return this.hslToHex(baseColor.hue, baseColor.sat, baseColor.light);
            }

            const color = this.color(
                lightBackground,
                this.changeHue(baseColor.hue, maxVariationsCounter * HUE_DIFFERENCE),
                baseColor.sat,
                this.changeLight(baseColor.light),
            );

            maxVariationsCounter += 1;

            return this.hslToHex(color.hue, color.sat, color.light);
        });

        return colors;
    }

    public color(
        lightBackground: boolean = true,
        hue: number | undefined = undefined,
        sat: number | undefined = undefined,
        light: number | undefined = undefined,
    ) {
        let minLight = MIN_LIGHT;
        let maxLight = MAX_LIGHT;

        if (lightBackground) {
            minLight = MIN_LIGHT_DARKER;
            maxLight = MAX_LIGHT_DARKER;
        }

        let newHue = hue || this.randomNumber(MIN_HUE, MAX_HUE);

        if (newHue > 288 && newHue < 316) {
            newHue = this.randomNumber(316, 360);
        } else if (newHue > 280 && newHue < 288) {
            newHue = this.randomNumber(260, 280);
        }

        const newSat = sat || this.randomNumber(MIN_SAT, MAX_SAT);
        const newLight = light || this.randomNumber(minLight, maxLight);

        return {
            hue: newHue,
            sat: newSat,
            light: newLight,
        };
    }

    public hexToRgba(color: string, opacity: number) {
        let hex = color.replace('#', '');
        let opacityParam = opacity;

        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        /* Backward compatibility for whole number based opacity values. */
        if (opacity > 1 && opacity <= 100) {
            opacityParam /= 100;
        }

        return `rgba(${r},${g},${b},${opacityParam})`;
    }

    public getMarketEventColor(marketCode: MarketCycleCode, transparence: number): string {
        switch (marketCode) {
            case MarketCycleCode.ST:
                return `rgba(132, 204, 6, ${transparence})`;
            case MarketCycleCode.CR:
                return `rgba(232, 74, 74, ${transparence})`;
            case MarketCycleCode.RE:
                return `rgba(0, 153, 30, ${transparence})`;
            default:
                return `rgba(241, 131, 44, ${transparence})`;
        }
    }

    private rgbToHex(red: number, green: number, blue: number) {
        return `#${[red, green, blue].map((value) => {
            const hex = value.toString(16);
            return hex.length === 1 ? `0${hex}` : hex;
        }).join('')}`;
    }

    private hslToHex(hue: number, sat: number, light: number): string {
        const lightPercentage = light / 100;
        const a = (sat * Math.min(lightPercentage, 1 - lightPercentage)) / 100;
        const f = (n: number) => {
            const k = (n + hue / 30) % 12;
            const color = lightPercentage - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    private changeHue(hue: number, rotate: number): number {
        return hue + rotate > MAX_HUE ? (hue + rotate) - MAX_HUE : hue + rotate;
    }

    private changeLight(light: number, lightBackground: boolean = true): number {
        let maxLight = MAX_LIGHT;

        if (lightBackground) {
            maxLight = MAX_LIGHT_DARKER;
        }

        return light + SCALE_LIGHT > maxLight ? maxLight : light + SCALE_LIGHT;
    }

    private randomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min) + min);
    }
}
