export function convertDurationToPeriod(duration: number): string {
    const years = Math.trunc(duration / 12);
    const rest = duration % 12;

    if (years > 0) {
        return `${years}Y`.padStart(3, '0');
    }

    return `${rest}M`.padStart(3, '0');
}

export function parseIntArrayOfStringOrString(input: string[] | string, radix = 10): number[] | null {
    if (input) {
        const tempArray = Array.isArray(input) ? input : [input];
        return tempArray.map((item) => parseInt(item, radix));
    }
    return null;
}

export type PopupParams = {
    url: string,
    title: string,
    popupWidth: number,
    popupHeight: number,
}

export function popupCenter({ url, title, popupWidth, popupHeight }: PopupParams) {
    const dualScreenLeft = window.screenLeft ?? window.screenX;
    const dualScreenTop = window.screenTop ?? window.screenY;

    const width = window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;
    const height = window.innerHeight ?? document.documentElement.clientHeight ?? screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
    const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;
    const popupConfiguration = [
        'scrollbars=yes',
        `width=${popupWidth / systemZoom}`,
        `height=${popupHeight / systemZoom}`,
        `top=${top}`,
        `left=${left}`,
    ].join(',');

    const newWindow = window.open(url, title, popupConfiguration);
    newWindow?.focus();
    return newWindow;
}
