import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { AdvancedFilterBooleanValue } from '~/app/shared/types/advanced-filter/advanced-filter-boolean-value.type';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';

export const PORTFOLIO_CREATE_PORTFOLIO_LIST_KEY = 'CREATE_PORTFOLIO';

export const PORTFOLIO_CREATE_SHARE_LIST_KEY = 'CREATE_PORTFOLIO';

export const PORTFOLIO_CREATE_SHARE_LIST_EXTRA_FILTERS = [
    {
        uid: 'extra_active',
        name: 'Part active',
        type: FacetType.BOOLEAN,
        value: {
            property: 'isAlive',
            value: true,
        },
    } as AdvancedFilterGeneric<AdvancedFilterBooleanValue>,
];
