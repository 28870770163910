import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    catchError,
    of,
} from 'rxjs';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { ActiveRisksModule } from '~/app/shared/enums/active-risks-module.enum';
import {
    ActiveRisk,
    ActiveRiskSort,
    ActiveRiskValueRequest,
    ActiveRiskValueResponse,
} from '~/app/shared/interfaces/RiskIndicator';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';

@Injectable({
    providedIn: 'root',
})
export class ActiveRisksService {
    constructor(
        private http: HttpClient,
        private indicatorsUtilsService: IndicatorsUtilsService,
        private authFacade: AuthenticationFacade,
        private authorisationFacade: AuthorisationFacade,
    ) {}

    getActiveRisks(module: ActiveRisksModule, q?: string) {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        const plan = this.authorisationFacade.getPlanSnapshot();

        return this.getActiveRisksRaw(languageCode, plan?.id ?? null, module, q);
    }

    getActiveRisksValuesForSharesOrCategories(
        sharesId: Array<number>,
        categoriesId: Array<number>,
        activeRisks: ActiveRiskValueRequest[],
        endDate: string | null = null,
        includeValues: boolean = true,
    ) {
        if (activeRisks.length === 0 || (sharesId.length === 0 && categoriesId.length === 0)) { return of([]); }
        const references = this.indicatorsUtilsService.createReferencesForRequest(sharesId, categoriesId);

        return this.http.post<Readonly<ActiveRiskValueResponse[]>>(
            '/active-risks/values',
            {
                references,
                activeRisks,
                ...(endDate ? { endDate } : {}),
                includeValues,
            },
        ).pipe(
            catchError(() => {
                const refs: ActiveRiskValueResponse[] = references.map((reference) => ({
                    ...reference,
                    activeRisks: [] as Array<ActiveRisk>,
                }));

                return of(refs);
            }),
        );
    }

    @Memoize({
        isObservable: true,
        maxSize: 3,
    })
    private getActiveRisksRaw(
        _languageCode: string,
        _planId: number | null,
        module: ActiveRisksModule,
        q?: string,
    ) {
        return this.http.get<Readonly<ActiveRiskSort[]>>('/active-risks', {
            params: {
                ...(q ? { q } : {}),
                module,
            },
        });
    }
}
