export function roundWeight(weight: number | undefined): number {
    return parseFloat((weight ?? 0).toFixed(4));
}

export function roundAmount(amount: number | undefined): number {
    return parseFloat((amount ?? 0).toFixed(2));
}

export function roundQuantity(quantity: number | undefined): number {
    return parseFloat((quantity ?? 0).toFixed(2));
}

export function getRandomInt(min: number, max: number): number {
    const ceilMin = Math.ceil(min);
    const floorMax = Math.floor(max);
    return Math.floor(Math.random() * (floorMax - ceilMin) + ceilMin);
}
