import {
    Injectable,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { ApplicationFacade } from '~/app/core/state/application/application-facade/application.facade';
import { CardType } from '~/app/shared/enums/card-type.enum';
import {
    ActiveRiskSort,
    NotationSort,
    RiskIndicatorSort,
} from '~/app/shared/interfaces/RiskIndicator';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { FacetValue } from '~/app/shared/types/facet/facet-value.type';
import { Facet } from '~/app/shared/types/facet/facet.type';
import { FieldOption } from '~/app/shared/types/field-option.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortOption } from '~/app/shared/types/sort-display/sort-option.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';
import { SortRequestValue } from '~/app/shared/types/sort-request-value.type';
import { getFacetsByKey } from '~/app/shared/utils/advanced-sort/advanced-sort.utils';


@Injectable({
    providedIn: 'root',
})
export class SortsUtilsService {
    constructor(
        private applicationFacade: ApplicationFacade,
        private translocoService: TranslocoService,
    ) {
    }

    createSortsForRequest(sortValues: SortValue[]) : Array<SortRequestValue> {
        const sorts: Array<SortRequestValue> = [];
        sortValues.forEach((sortValue) => {
            switch (sortValue.type) {
                case CardType.EXPO:
                    sorts.push({
                        id: sortValue.code,
                        property: sortValue.field || '',
                        direction: sortValue.direction,
                    });
                    break;
                case CardType.FACET:
                    sorts.push({
                        property: sortValue.sortCode ?? sortValue.code,
                        direction: sortValue.direction,
                    });
                    break;
                case CardType.RISK_INDICATOR:
                case CardType.ACTIVE_RISK:
                case CardType.NOTATION:
                    sorts.push({
                        code: sortValue.code,
                        period: sortValue.period ?? null,
                        marketCycle: sortValue.marketCycle ?? '',
                        benchmark: sortValue.benchmark ?? '',
                        direction: sortValue.direction,
                    });
                    break;
                default:
                    break;
            }
        });
        return sorts;
    }

    createSortOptions(
        defaultOptions: SortOption[],
        displayValues: DisplayValue[],
        mapOptions: {[key:string]: FieldOption},
        facetsFamilies: FacetFamily[],
        riskIndicators: readonly RiskIndicatorSort[],
        activeRisks: readonly ActiveRiskSort[],
        notations: readonly NotationSort[],
    ) : SortOption[] {
        const facetsByKey = getFacetsByKey(facetsFamilies);
        let optionTmp: FieldOption | null = null;
        let facetTmp: Facet | undefined;
        let facetValueTmp: FacetValue | undefined;
        let riskIndicatorTmp: RiskIndicatorSort | undefined;
        let notationTmp: NotationSort | undefined;
        let activeRiskTmp: ActiveRiskSort | undefined;

        return [
            ...defaultOptions.map((defaultOption) => ({
                ...defaultOption,
                name: this.translocoService.translate(defaultOption.name),
            })),
            ...(displayValues ?? []).reduce((acc: SortOption[], displayValue: DisplayValue) => {
                switch (displayValue.type) {
                    case CardType.FACET: {
                        optionTmp = mapOptions[displayValue.code];
                        if (optionTmp && optionTmp.sortable) {
                            acc.push({
                                name: optionTmp.translatable ? this.translocoService.translate(optionTmp.name) : optionTmp.name,
                                type: displayValue.type,
                                sortCode: optionTmp.sortCode ?? optionTmp.code,
                                value: {
                                    ...displayValue,
                                },
                            });
                        }
                        break;
                    }
                    case CardType.EXPO: {
                        facetTmp = facetsByKey[displayValue.field as string];
                        facetValueTmp = (facetTmp?.values ?? []).find((facetValue) => facetValue.value === displayValue.code);
                        if (facetTmp && facetValueTmp) {
                            acc.push({
                                name: `${this.translocoService.translate('common.expo')} ${facetValueTmp.name} (${this.translocoService.translate(`common.expo_field.${facetTmp.field}`)})`,
                                type: displayValue.type,
                                sortCode: displayValue.code,
                                value: {
                                    ...displayValue,
                                },
                            });
                        }
                        break;
                    }
                    case CardType.NOTATION: {
                        notationTmp = notations.find((notation) => notation.code === displayValue.code);
                        if (notationTmp) {
                            acc.push({
                                name: `${notationTmp.name}
                                    ${displayValue.period ? this.translocoService.translate(`common.periods.${displayValue.period}.label`) : ''}
                                    (${this.translocoService.translate(`common.benchmark.${displayValue.benchmark ?? ''}.short_label`)})
                                `,
                                type: displayValue.type,
                                sortCode: displayValue.code,
                                value: {
                                    ...displayValue,
                                },
                            });
                        }
                        break;
                    }
                    case CardType.RISK_INDICATOR: {
                        riskIndicatorTmp = riskIndicators.find((riskIndicator) => riskIndicator.code === displayValue.code);
                        if (riskIndicatorTmp) {
                            acc.push({
                                name: `${riskIndicatorTmp.name} ${this.translocoService.translate(`common.periods.${displayValue.period ?? ''}.label`)}`,
                                type: displayValue.type,
                                sortCode: displayValue.code,
                                value: {
                                    ...displayValue,
                                },
                            });
                        }
                        break;
                    }
                    case CardType.ACTIVE_RISK: {
                        activeRiskTmp = activeRisks.find((activeRisk) => activeRisk.code === displayValue.code);
                        if (activeRiskTmp) {
                            acc.push({
                                name: `${activeRiskTmp.name} ${this.translocoService.translate(`common.periods.${displayValue.period ?? ''}.label`)} (${this.translocoService.translate(`common.benchmark.${displayValue.benchmark ?? ''}.short_label`)})`,
                                type: displayValue.type,
                                sortCode: displayValue.code,
                                value: {
                                    ...displayValue,
                                },
                            });
                        }
                        break;
                    }
                    default:
                        break;
                }
                return acc;
            }, []),
        ];
    }
}
