import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Client } from '~/app/shared/types/client.type';
import { Collection } from '~/app/shared/types/collection.type';
import { ListQueryBody } from '~/app/shared/types/list-query-body.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    constructor(private http: HttpClient) {}

    getClients(query: ListQueryBody) {
        return this.http.get<Readonly<Collection<Client>>>('/clients', { params: { ...query } });
    }

    getClientPortfolios(clientId: number) {
        return this.http.get<Readonly<Portfolio[]>>(`/clients/${clientId}/portfolios`);
    }
}
