import { PERMISSIONS } from '~/app/core/constants/permissions.constants';
import { ProjectionViewModeValue } from '~/app/shared/enums/projection-view-mode-value.enum';
import { OptionAuthorisation } from '~/app/shared/types/option-authorisation.type';

export const PROJECTION_ADD_PORTFOLIO_LIST_KEY = 'PROJECTION_ADD_PORTFOLIO';

export const PROJECTION_HORIZON_RANGES = {
    shortTerm: {
        default: 3,
        min: 1,
        max: 3,
    },
    midTerm: {
        default: 8,
        min: 4,
        max: 10,
    },
    longTerm: {
        default: 20,
        min: 11,
        max: 30,
    },
};

export const PROJECTION_VIEW_MODES_DEFAULT_VALUES = {
    MAIN: [
        ProjectionViewModeValue.PROPOSITION,
        ProjectionViewModeValue.ARBITRAGES,
        ProjectionViewModeValue.FUNDS_LIST,
    ],
    PRO: [
        ProjectionViewModeValue.PROPOSITION,
        ProjectionViewModeValue.PROJECTION,
        ProjectionViewModeValue.ARBITRAGES,
        ProjectionViewModeValue.FUNDS_LIST,
    ],
    ADVANCED: [
        ProjectionViewModeValue.PROPOSITION,
        ProjectionViewModeValue.PROJECTION,
        ProjectionViewModeValue.ALLOCATION_CONTRIBUTION,
        ProjectionViewModeValue.ARBITRAGES,
        ProjectionViewModeValue.FUNDS_LIST,
    ],
};

export const PROJECTION_VIEW_MODES_VALUES: OptionAuthorisation<ProjectionViewModeValue>[] = [
    {
        id: ProjectionViewModeValue.PROPOSITION,
        label: 'projection.view_mode_values.proposition',
    },
    {
        id: ProjectionViewModeValue.PROJECTION,
        label: 'projection.view_mode_values.projection',
        permissions: [PERMISSIONS.SIMULATION_SAVING_PLAN_GRAPH_ACCESS],
    },
    {
        id: ProjectionViewModeValue.ALLOCATION_CONTRIBUTION,
        label: 'projection.view_mode_values.allocation_contribution',
        permissions: [PERMISSIONS.SIMULATION_SAVING_PLAN_ALLOCATION_CONTRIBUTION_ACCESS],
    },
    {
        id: ProjectionViewModeValue.ARBITRAGES,
        label: 'projection.view_mode_values.arbitrages',
    },
    {
        id: ProjectionViewModeValue.FUNDS_LIST,
        label: 'projection.view_mode_values.funds_list',
    },
];
