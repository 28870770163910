import { Injectable } from '@angular/core';
import {
    Actions,
    ofActionSuccessful,
    Select,
    Store,
} from '@ngxs/store';
import {
    map,
    Observable,
} from 'rxjs';

import {
    AddPortfolioListAction,
    ApplyDisplayValuesAction,
    ApplySortValuesAction,
    AutocompleteSearchAction,
    ChangeAlertIdsAction,
    ChangeCurrentPortfolioListAction,
    ChangePortfolioUniverseAction,
    ChangeSubSegmentationAction,
    ClearPortfoliosAction,
    DeletePortfoliosAction,
    GetPortfolioMetaDataAction,
    GetPortfolioUniversesAction,
    PostAlertAction,
    ResetAndSearchAction,
    ResetCurrentDisplayValuesAction,
    SaveDefaultDisplayValuesAction,
    SearchAction,
    SelectAllPortfoliosAction,
    SelectUnselectPortfolioAction,
    SetFiltersAction,
    SetPortfolioViewTypeAction,
    SetSelectedPortfoliosAction,
    UnselectAllPortfoliosAction,
} from '~/app/core/state/portfolios-list/portfolios-list.actions';
import { PortfoliosListState } from '~/app/core/state/portfolios-list/portfolios-list.state';
import { ViewType } from '~/app/shared/enums/view-type.enum';
import { getPortfolioDefaultFiltersProperties } from '~/app/shared/services/advanced-filters-utils/advanced-filters-default-value';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValue } from '~/app/shared/types/advanced-filter/advanced-filter-value.type';
import { AlertActionParam } from '~/app/shared/types/alert-action/alert-action-param.type';
import { AlertTypeRefs } from '~/app/shared/types/alert-type-refs.type';
import { ExtendedTabItem } from '~/app/shared/types/extended-tab-item.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { PortfolioSelected } from '~/app/shared/types/portfolio/portfolio-selected.type';
import { PortfolioUniverse } from '~/app/shared/types/portfolio/portfolio-universe.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';

@Injectable({
    providedIn: 'root',
})
export class PortfoliosListFacade {
    @Select(PortfoliosListState.getAlertsIdsInCurrentPortfolioList)
    public alertsIds$!: Observable<Array<number>>;

    @Select(PortfoliosListState.getAlertsInCurrentPortfolioList)
    public alertsSelected$!: Observable<AlertTypeRefs[]>;

    @Select(PortfoliosListState.getAlerts)
    public alerts$!: Observable<AlertTypeRefs[]>;

    @Select(PortfoliosListState.getAlertsCount)
    public alertsCount$!: Observable<number>;

    @Select(PortfoliosListState.getCurrentPortfolioSubSegmentation)
    public currentSubSegmentation$!: Observable<number>;

    @Select(PortfoliosListState.getCurrentPortfolioUniverse)
    public currentUniverse$!: Observable<PortfolioUniverse>;

    @Select(PortfoliosListState.getPortfolioUniverses)
    public portfolioUniverses$!: Observable<PortfolioUniverse[]>;

    @Select(PortfoliosListState.getCurrentPortfolioListViewType)
    public currentViewType$!: Observable<ViewType>;

    @Select(PortfoliosListState.getCurrentSortValues)
    public currentSortValues$!: Observable<SortValue[]>;

    @Select(PortfoliosListState.getCurrentSortCount)
    public currentSortCount$?: Observable<number>;

    @Select(PortfoliosListState.isLoading)
    public isLoading$!: Observable<boolean>;

    @Select(PortfoliosListState.indicatorsIsLoading)
    public indicatorsIsLoading$?: Observable<{ [portfolioId: number]: boolean }>;

    @Select(PortfoliosListState.isSearchDisabled)
    public isSearchDisabled$!: Observable<boolean>;

    @Select(PortfoliosListState.getPortfolios)
    public portfolios$!: Observable<Portfolio[]>;

    @Select(PortfoliosListState.getSelectedPortfolioIds)
    public selected$!: Observable<Array<number>>;

    @Select(PortfoliosListState.getSelectedPortfolios)
    public portfoliosSelected$!: Observable<Array<PortfolioSelected>>;

    @Select(PortfoliosListState.getDisplayIndicatorPortfolio)
    public displayIndicator$!: Observable<Array<DisplayValue>>;

    @Select(PortfoliosListState.getDefaultDisplayValues)
    public defaultDisplayValues$!: Observable<DisplayValue[]>;

    @Select(PortfoliosListState.getCurrentPortfolioSelectedCount)
    public selectedCount$!: Observable<number>;

    @Select(PortfoliosListState.getSubSegmentationTabs)
    public subSegmentations$!: Observable<Array<ExtendedTabItem>>;

    @Select(PortfoliosListState.getFacets)
    public facets$!: Observable<FacetFamily[]>;

    @Select(PortfoliosListState.getCurrentFilters)
    public currentFilters$!: Observable<AdvancedFilterGeneric[]>;

    @Select(PortfoliosListState.getCurrentFiltersCount)
    public currentFiltersCount$!: Observable<number>;

    @Select(PortfoliosListState.getQuery)
    public query$!: Observable<string>;


    public currentFiltersTranslated$: Observable<AdvancedFilterGeneric[]> = this.currentFilters$
        .pipe(
            map((filters) => filters.map((item) => {
                const defaultFilters = getPortfolioDefaultFiltersProperties();
                if (defaultFilters.map((defaultFilter) => defaultFilter.property).includes(item.value.property ?? '')) {
                    return item;
                }

                const filterName = this.getFacetsSnapshot()
                    .find((facet) => facet.values.find((value) => value.field === item.value.property))
                    ?.values.find((value) => value.field === item.value.property)
                    ?.name ?? item.name;
                return {
                    ...item,
                    name: filterName,
                };
            })),
        );

    constructor(
        private store: Store,
        private actions$: Actions,
    ) {}

    public addPortfolioList(key: string, overrideList?: boolean, extraFilters?: AdvancedFilterGeneric[]): Observable<unknown> {
        return this.store.dispatch(new AddPortfolioListAction(key, overrideList, extraFilters));
    }

    public applyDisplayValues(displayValues: DisplayValue[]): Observable<unknown> {
        return this.store.dispatch(new ApplyDisplayValuesAction(displayValues));
    }

    public applySortValues(sortValues: SortValue[]): Observable<unknown> {
        return this.store.dispatch(new ApplySortValuesAction(sortValues));
    }

    public changeAlertIds(alertIds: number[]) {
        return this.store.dispatch(new ChangeAlertIdsAction(alertIds));
    }

    public changeCurrentPortfolioList(key: string) {
        this.store.dispatch(new ChangeCurrentPortfolioListAction(key));
    }

    public changeSubSegmentation(id: number) {
        return this.store.dispatch(new ChangeSubSegmentationAction(id));
    }

    public clearPortfolios() {
        return this.store.dispatch(new ClearPortfoliosAction());
    }

    public getPortfolioMetaData(refreshCache: boolean = false) {
        return this.store.dispatch(new GetPortfolioMetaDataAction(refreshCache));
    }

    public deletePortfolios(portfoliosIds: number[]): Observable<unknown> {
        return this.store.dispatch(new DeletePortfoliosAction(portfoliosIds));
    }

    public getPortfolioUniverses() {
        return this.store.dispatch(new GetPortfolioUniversesAction());
    }

    public postAlertAction(action: AlertActionParam) {
        return this.store.dispatch(new PostAlertAction(action));
    }

    public resetAndSearch() {
        return this.store.dispatch(new ResetAndSearchAction());
    }

    public resetCurrentDisplayValues(resetSearch: boolean = false): Observable<unknown> {
        return this.store.dispatch(new ResetCurrentDisplayValuesAction(resetSearch));
    }

    public saveDefaultDisplayValues(displayValues: DisplayValue[]) {
        return this.store.dispatch(new SaveDefaultDisplayValuesAction(displayValues));
    }

    public search() {
        return this.store.dispatch(new SearchAction());
    }

    public selectUnselectPortfolio(id: number, single = false) {
        return this.store.dispatch(new SelectUnselectPortfolioAction(id, single));
    }

    public selectAllPortfolios() {
        return this.store.dispatch(new SelectAllPortfoliosAction());
    }

    public setSelectedPortfolios(selected: PortfolioSelected[]) {
        return this.store.dispatch(new SetSelectedPortfoliosAction(selected));
    }

    public setPortfolioViewType(type: ViewType) {
        return this.store.dispatch(new SetPortfolioViewTypeAction(type));
    }

    public unselectAllPortfolios() {
        return this.store.dispatch(new UnselectAllPortfoliosAction());
    }

    public changePortfolioUniverse(id: number) {
        return this.store.dispatch(new ChangePortfolioUniverseAction(id));
    }

    public onFilterChange(filters: AdvancedFilterValue[]) {
        return this.store.dispatch(new SetFiltersAction(filters));
    }

    public autocompleteSearch(suggestion: string, resetFilters: boolean = false, resetSubsegmentations = false) {
        return this.store.dispatch(new AutocompleteSearchAction(suggestion, resetFilters, resetSubsegmentations));
    }

    public clearAutocompleteSearch() {
        return this.store.dispatch(new AutocompleteSearchAction('', false, false));
    }

    public getFacetsSnapshot() {
        return this.store.selectSnapshot(PortfoliosListState.getFacets);
    }

    public getRequestIdOfPortfolios() {
        return this.store.selectSnapshot(PortfoliosListState.getRequestIdOfPortfolios);
    }

    public getSelectedPortfolioIdsSnapshot(): number[] {
        return this.store.selectSnapshot(PortfoliosListState.getSelectedPortfolioIds);
    }

    public getDisplayIndicatorSnapshot(): Array<DisplayValue> {
        return this.store.selectSnapshot(PortfoliosListState.getDisplayIndicatorPortfolio);
    }

    public getCurrentListSnapshot(): string {
        return this.store.selectSnapshot(PortfoliosListState.getCurrentList);
    }

    public getCurrentPortfolioCountSnapshot(): number {
        return this.store.selectSnapshot(PortfoliosListState.getCurrentPortfolioCount);
    }

    public onDeletePortfolio(): Observable<unknown> {
        return this.actions$
            .pipe(
                ofActionSuccessful(
                    DeletePortfoliosAction,
                ),
            );
    }
}
