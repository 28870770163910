import {
    HttpClient,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moized } from 'moize';
import {
    Observable,
    of,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { Period } from '~/app/shared/enums/period.enum';
import { AdvancedFilterValueGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-value-generic.type';
import { Collection } from '~/app/shared/types/collection.type';
import { ListQueryBody } from '~/app/shared/types/list-query-body.type';
import { SearchMetaData } from '~/app/shared/types/search/search-metadata.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';
import { SearchUniverses } from '~/app/shared/types/search/search-universes.type';
import { CollectionOfShares } from '~/app/shared/types/shares/collection-of-shares.type';
import { DownloadSharesExcelBody } from '~/app/shared/types/shares/download-shares-excel-body.type';
import { NearestShares } from '~/app/shared/types/shares/nearest-share-response.type';
import { ShareCategory } from '~/app/shared/types/shares/share-category.type';
import { ShareDetails } from '~/app/shared/types/shares/share-details.type';
import { ShareReportingResult } from '~/app/shared/types/shares/share-reporting-result.type';
import {
    ShareSearchSuggestion,
} from '~/app/shared/types/shares/share-search-suggestion.type';
import { Share } from '~/app/shared/types/shares/share.type';
import { SubSegmentation } from '~/app/shared/types/sub-segmentation.type';
import { formatMetadatasShare } from '~/app/shared/utils/metadata/metadata.utils';

@Injectable({
    providedIn: 'root',
})
export class SharesService {
    constructor(
        private http: HttpClient,
        private authFacade: AuthenticationFacade,
    ) { }

    public getSharesMetadata(
        body: {
            q?: string,
            filters?: AdvancedFilterValueGeneric[],
            searchUniverse?: number,
            searchUniverses?: SearchUniverses,
            subSegmentation?: string,
        },
    ) {
        const language = this.authFacade.getUserLanguageSnapshot();
        return this.getSharesMetadataRaw(body, language);
    }

    public getSharesSearchSubSegmentations(
        body: {
            q?: string,
            filters?: AdvancedFilterValueGeneric[],
            searchUniverse?: number,
            searchUniverses?: SearchUniverses,
            subSegmentation?: string,
        },
    ): Observable<SubSegmentation[]> {
        const language = this.authFacade.getUserLanguageSnapshot();
        return this.getSharesSubsegmentationsRaw(body, language);
    }

    public search(query: SearchQueryBody) {
        return this.http.post<Readonly<CollectionOfShares>>('/shares/search', query).pipe(
            map((result) => ({
                ...result,
                ...(
                    result.metadata
                        ? {
                            metadata: formatMetadatasShare(
                                result.metadata,
                            ),
                        }
                        : {}
                ),
            })),
        );
    }

    public suggestions(query: string, searchUniverse: number | undefined) {
        if (query.length <= 2) { return of([]); }
        return this.http.get<Readonly<ShareSearchSuggestion[]>>('/shares/suggestions', { params: {
            q: query,
            ...(searchUniverse ? { searchUniverse } : {}),
        } });
    }

    public getSearchMetaData(refreshCache = false, searchUniverse: number | undefined = undefined): Observable<Readonly<SearchMetaData>> {
        if (refreshCache) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const moizedFn = this.getSearchMetaDataRaw as Moized;
            moizedFn.clear();
        }

        return this.getSearchMetaDataRaw(searchUniverse);
    }

    public getShareDetails(id: number) {
        return this.http.get<Readonly<ShareDetails>>(`/shares/${id}/details`);
    }

    public getReporting(id: number, period: Period, endDate: string): Observable<ShareReportingResult> {
        return this.http.get<ShareReportingResult>(`/shares/${id}/reporting`, {
            params: {
                period,
                endDate,
            },
        });
    }

    public getCompatibleCategories(id: number) {
        return this.http.get<Readonly<ShareCategory[]>>(`/shares/${id}/compatible-categories`);
    }

    public getCategoriesSearch(query: ListQueryBody) {
        return this.http.get<Readonly<Collection<ShareCategory>>>('/shares/categories/search', { params: { ...query } });
    }

    public getCategories(categoryIds: number[]) {
        return this.http.get<Readonly<ShareCategory[]>>('/shares/categories', { params: { ids: categoryIds } });
    }

    public getSimilarShares(id: number, query: ListQueryBody) {
        return this.http.get<Readonly<Collection<Share>>>(`/shares/${id}/similars`, { params: { ...query } });
    }

    public getNearestShares(shareId: number, nearestType: string[] = ['category', 'subcategory', 'similar']) {
        let params = new HttpParams();
        nearestType.forEach((source) => {
            params = params.append('sources', source);
        });
        return this.http.get<NearestShares>(`/shares/${shareId}/nearest`, { params });
    }

    public getOtherShares(id: number, query: ListQueryBody) {
        return this.http.get<Readonly<Collection<Share>>>(`/funds/${id}/shares`, { params: { ...query } });
    }

    public downloadExcel(body: DownloadSharesExcelBody) {
        return this.http.post('/shares/spreadsheet', body, {
            responseType: 'arraybuffer',
        });
    }

    @Memoize({
        isDeepEqual: true,
        isObservable: true,
    })
    private getSharesMetadataRaw(
        body: {
            q?: string,
            filters?: AdvancedFilterValueGeneric[],
            searchUniverse?: number,
            searchUniverses?: SearchUniverses,
            subSegmentation?: string,
        },
        _languageCode: string, // keep for memoization
    ) {
        return this.http.post<SearchMetaData>('/shares/search/metadata?facets=true', body).pipe(
            map((metadata) => formatMetadatasShare(metadata)),
            map((metadata) => metadata.facets),
        );
    }

    @Memoize({
        isDeepEqual: true,
        isObservable: true,
    })
    private getSharesSubsegmentationsRaw(
        body: {
            q?: string,
            filters?: AdvancedFilterValueGeneric[],
            searchUniverse?: number,
            searchUniverses?: SearchUniverses,
            subSegmentation?: string,
        },
        _languageCode: string, // keep for memoization
    ) {
        return this.http.post<SearchMetaData>('/shares/search/metadata?subSegmentations=true', body).pipe(
            map((metadata) => formatMetadatasShare(metadata)),
            map((metadata) => metadata.subSegmentations),
        );
    }

    @Memoize({
        isObservable: true,
    })
    private getSearchMetaDataRaw(searchUniverse: number | undefined = undefined): Observable<Readonly<SearchMetaData>> {
        return this.http.get<Readonly<SearchMetaData>>('/shares/search/metadata', {
            params: {
                ...(searchUniverse ? { searchUniverse } : {}),
            },
        }).pipe(
            map((metadata) => formatMetadatasShare(
                metadata,
            )),
        );
    }
}
