export enum OperationType {
    ADD_SHARES_TO_PORTFOLIO = 1,
    SIMULATE_ALLOCATION,
    SIMULATE_NEW_PORTFOLIO,
    ENHANCE_ALLOCATION,
    ADD_TOP_SHARES_TO_PORTFOLIO,
    RESUME_UNLOCKED_FEATURE,
    REFRESH_CHALLENGE,
    OPTIMISE_PORTFOLIO,
    REBALANCE_TO_ADVICE,
    LAUNCH_SIMULATION,
}
