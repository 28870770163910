/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
    StateOperator,
    Store,
} from '@ngxs/store';
import { subBusinessDays } from 'date-fns';
import { clone } from 'ramda';
import {
    EMPTY,
    forkJoin,
    Observable,
} from 'rxjs';
import {
    tap,
} from 'rxjs/operators';

import { ENVESTBOARD_GROUP_ID } from '~/app/core/constants/contacts.constants';
import {
    PORTFOLIO_CREATE_PORTFOLIO_LIST_KEY,
    PORTFOLIO_CREATE_SHARE_LIST_EXTRA_FILTERS,
    PORTFOLIO_CREATE_SHARE_LIST_KEY,
} from '~/app/core/constants/portfolio-create.constants';
import {
    DISPLAY_INDICATORS_VALUE_FOR_COMPOSITION,
    PORTFOLIO_DETAIL_DEFAULT_PERIOD,
    PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES,
} from '~/app/core/constants/portfolio-detail.constants';
import { SHARES_SEARCH_FIELDS } from '~/app/core/constants/shares.constants';
import { ActiveRisksService } from '~/app/core/services/api/active-risks/active-risks.service';
import { AlertsService } from '~/app/core/services/api/alerts/alerts.service';
import { ClientsService } from '~/app/core/services/api/clients/clients.service';
import { ContactsService } from '~/app/core/services/api/contacts/contacts.service';
import { NotationsService } from '~/app/core/services/api/notations/notations.service';
import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import { RiskIndicatorsService } from '~/app/core/services/api/risk-indicators/risk-indicators.service';
import { SharesService } from '~/app/core/services/api/shares/shares.service';
import { GetForexAction } from '~/app/core/state/application/application-action/application.actions';
import { ApplicationState } from '~/app/core/state/application/application-state/application.state';
import {
    AllocationChangeTypeAction,
    ApplyOperationAction,
    ChangeCreationTypeAction,
    ChangeCreationUserJourneyAction,
    ChangePortfolioDocumentsAction,
    ChangePortfolioErrorAction,
    ChangePortfolioFieldAction,
    ChangePortfolioStateAction,
    ChangePortfolioTypeAction,
    ChangePortfolioVideosAction,
    ChangeViewModeAction,
    CreateCurrentPortfolioAction,
    CreateCurrentPortfolioSuccessAction,
    DeletePortfolioDocumentAction,
    DeletePortfolioVideoAction,
    EnableTestSRRIAction,
    GetAllocationSharesAction,
    GetClientsAction,
    GetCurrentForexAction,
    GetPortfolioAction,
    GetPortfolioDocumentsAction,
    GetPortfolioLastAllocationAction,
    GetPortfolioVideosAction,
    GetRiskIndicatorsOfAllocationSharesAction,
    GetShareCategoriesAction,
    InitPortfolioAction,
    InitPortfolioDetailAction,
    PostAlertAction,
    RefreshCurrentCalculatedSRRIAction,
    ReinitAllocationAction,
    ResetAction,
    ResetReturnUrlAction,
    ResetViewModeDisplayAction,
    SetViewModeDisplayValuesAction,
    TestSRRIAction,
    UpdateAllocationAction,
    UpdateAllocationInStateAction,
    UpdateAllocationsMap,
    UpdateCompositionViewModeParams,
    UpdateGlobalParams,
    UpdatePortfolioAction,
    UpdateRiskIndicatorsViewModeParams,
} from '~/app/core/state/portfolios-details/portfolios-details.actions';
import { AddPortfolioListAction } from '~/app/core/state/portfolios-list/portfolios-list.actions';
import { AddShareListAction } from '~/app/core/state/shares-list/shares-list.actions';
import { AllocationChangeType } from '~/app/shared/enums/allocation-change-type.enum';
import { CreationPortfolioType } from '~/app/shared/enums/creation-portfolio-type.enum';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { GroupCompositionViewMode } from '~/app/shared/enums/group-composition-view-mode.enum';
import { OperationType } from '~/app/shared/enums/operation-type.enum';
import { PermissionType } from '~/app/shared/enums/permission-type.enum';
import { PortfolioType } from '~/app/shared/enums/portfolio-type.enum';
import { PortfolioViewModeValue } from '~/app/shared/enums/portfolio-view-mode-value.enum';
import { PortfoliosState } from '~/app/shared/enums/portfolios-state.enum';
import { USER_JOURNEY } from '~/app/shared/enums/user-journey.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';
import { updateAllocationForRequest } from '~/app/shared/services/portfolios-utils/portfolios-utils.service';
import { AllocationGroupPrices } from '~/app/shared/types/allocation/allocation-group-prices.type';
import { AllocationToUpdate } from '~/app/shared/types/allocation/allocation-to-update.type';
import { Allocation } from '~/app/shared/types/allocation/allocation.type';
import { Client } from '~/app/shared/types/client.type';
import { Contact } from '~/app/shared/types/contacts/contact.type';
import { DocumentItem } from '~/app/shared/types/document-item.type';
import { ErrorType } from '~/app/shared/types/error.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { PortfolioAlert } from '~/app/shared/types/portfolio/portfolio-alert.type';
import { GlobalParams } from '~/app/shared/types/portfolio/portfolio-detail-params/global-params.type';
import { PortfolioDetailsParams } from '~/app/shared/types/portfolio/portfolio-details-params.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';
import { ShareCategory } from '~/app/shared/types/shares/share-category.type';
import { Share } from '~/app/shared/types/shares/share.type';
import { Video } from '~/app/shared/types/video.type';
import {
    roundAmount,
    roundQuantity,
    roundWeight,
} from '~/app/shared/utils/calcul/calcul.utils';

import { SrrisService } from '../../services/api/srris/srris.service';

export type PortfolioCreateForm = {
    creationType: CreationPortfolioType | null,
    userJourney: USER_JOURNEY,
    portfolioType: PortfolioType,
}

export interface PortfoliosDetailsStateModel {
    clients: {
        values: Contact[],
        size: number,
        q: string,
        requestId: string,
        totalNumber: number,
        startAt: number,
    },
    shareCategories: {
        values: ShareCategory[],
        size: number,
        q: string,
        totalNumber: number,
        startAt: number,
    },
    createForm?: PortfolioCreateForm,
    error?: ErrorType,
    returnUrl: string | null,
    portfolioState: PortfoliosState,
    allocationChangeType: AllocationChangeType,
    portfolio?: CreatePortfolio | Portfolio,
    lastAllocation?: Allocation,
    nextCalculatedSrri?: number,
    allocations?: Map<string | number, AllocationGroupPrices>,
    viewModeDisplay: {
        [key: string]: PortfolioViewModeValue[],
    },
    params: PortfolioDetailsParams,
}

const defaults = {
    clients: {
        values: [],
        size: 20,
        q: '',
        requestId: '',
        totalNumber: 0,
        startAt: 0,
    },
    shareCategories: {
        values: [],
        size: 20,
        q: '',
        totalNumber: 0,
        startAt: 0,
    },
    returnUrl: null,
    allocationChangeType: AllocationChangeType.AMOUNT,
    portfolioState: PortfoliosState.SHOW,
    viewModeDisplay: {
        PRO: PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES.PRO,
        MAIN: PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES.MAIN,
        ADVANCED: PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
        SHARED: [],
    },
    params: {
        viewMode: ViewModeType.MAIN,
        globalParams: {
            period: PORTFOLIO_DETAIL_DEFAULT_PERIOD,
            endDate: subBusinessDays(new Date(), 1).toISOString(),
        },
        riskIndicatorsViewMode: ViewModeType.MAIN,
        compositionViewMode: GroupCompositionViewMode.ASSET,
        testSrriEnabled: true,
        displayTestSrri: false,
    },
};

function onChangeCurrentCreateForm(change: Partial<PortfolioCreateForm>): StateOperator<PortfoliosDetailsStateModel> {
    return (state: Readonly<PortfoliosDetailsStateModel>) => ({
        ...state,
        createForm: {
            ...(
                state.createForm || {
                    creationType: null,
                    userJourney: USER_JOURNEY.INITIAL,
                    portfolioType: PortfolioType.MODEL,
                }
            ),
            ...change,
        },
    });
}

function onChangeCurrentPortfolio(change: Partial<Portfolio>) : StateOperator<PortfoliosDetailsStateModel> {
    return (state: Readonly<PortfoliosDetailsStateModel>) => {
        if (!state.portfolio) {
            return state;
        }

        return {
            ...state,
            portfolio: {
                ...state.portfolio,
                ...change,
            },
        };
    };
}

export function isPortfolio(portfolio: Portfolio | CreatePortfolio): portfolio is Portfolio {
    return ('id' in portfolio);
}

function initAllocation(allocation: Allocation, portfolioType: PortfolioType) : Allocation {
    return {
        ...allocation,
        shares: allocation.shares.map((shareAlloc) => (
            {
                ...shareAlloc,
                isNewAllocation: false,
                newAllocationPrices: {
                    price: 0,
                    forex: shareAlloc.forex,
                    quantity: shareAlloc.quantity ? roundQuantity(shareAlloc.quantity) : shareAlloc.quantity,
                    weight: roundWeight(shareAlloc.weight),
                    amount: shareAlloc.amount ? roundAmount(shareAlloc.amount) : shareAlloc.amount,
                },
                diffAllocationPrices: {
                    price: 0,
                    forex: shareAlloc.forex,
                    weight: 0,
                    ...(portfolioType === PortfolioType.REAL ? { amount: 0 } : {}),
                },
            }
        )),
        cashes: allocation.cashes.map((cashAlloc) => (
            {
                ...cashAlloc,
                isNewAllocation: false,
                newAllocationPrices: {
                    price: 0,
                    forex: cashAlloc.forex,
                    quantity: cashAlloc.quantity ? roundQuantity(cashAlloc.quantity) : cashAlloc.quantity,
                    weight: roundWeight(cashAlloc.weight),
                    amount: cashAlloc.amount ? roundAmount(cashAlloc.amount) : cashAlloc.amount,
                },
                diffAllocationPrices: {
                    price: 0,
                    forex: cashAlloc.forex,
                    weight: 0,
                    ...(portfolioType === PortfolioType.REAL ? { amount: 0 } : {}),
                },
            }
        )),
    };
}

export function getShareSearchParams(sharesIds: string[]) : SearchQueryBody {
    return {
        fields: SHARES_SEARCH_FIELDS.join(','),
        q: '',
        filters: [{
            property: 'id',
            values: sharesIds,
            exclude: false,
            union: true,
        }],
        sorts: [],
        searchUniverse: 0,
        subSegmentation: '',
        includeMetadata: false,
        size: 50,
        startAt: 0,
        requestId: '',
    };
}

@State<PortfoliosDetailsStateModel>({
    name: 'portfoliosDetails',
    defaults,
})
@Injectable()
export class PortfoliosDetailsState {
    constructor(
        private alertsService: AlertsService,
        private clientsService: ClientsService,
        private contactsService: ContactsService,
        private shareDetailsService: SharesService,
        private srrisService: SrrisService,
        private portfoliosService: PortfoliosService,
        private riskIndicatorsService: RiskIndicatorsService,
        private notationsService: NotationsService,
        private activeRisksService: ActiveRisksService,
        private indicatorsUtilsService: IndicatorsUtilsService,
        private store: Store,
    ) { }

    static isDisplayedInCurrentViewMode(viewModeValue: PortfolioViewModeValue) {
        // eslint-disable-next-line max-len
        return createSelector([PortfoliosDetailsState], (state: PortfoliosDetailsStateModel) => state.viewModeDisplay[state.params.viewMode].some((item) => item === viewModeValue));
    }

    @Selector()
    static getUserJourney(state: PortfoliosDetailsStateModel): USER_JOURNEY {
        return state.createForm?.userJourney || USER_JOURNEY.INITIAL;
    }

    @Selector()
    static getPortfolioDetailsState(state: PortfoliosDetailsStateModel): PortfoliosState {
        return state.portfolioState;
    }

    @Selector()
    static getPortfolioDetailsCreationType(state: PortfoliosDetailsStateModel): CreationPortfolioType | null {
        return state.createForm?.creationType || null;
    }

    @Selector()
    static getCreateFormPortfolioType(state: PortfoliosDetailsStateModel): PortfolioType | undefined {
        return state.createForm?.portfolioType;
    }

    @Selector()
    static getNextCalculatedSrri(state: PortfoliosDetailsStateModel): number | undefined {
        return state.nextCalculatedSrri;
    }

    @Selector()
    static getPortfolioId(state: PortfoliosDetailsStateModel): number | null {
        if (!state.portfolio || !isPortfolio(state.portfolio)) {
            return null;
        }

        return state.portfolio.id;
    }

    @Selector()
    static getPortfolioClient(state: PortfoliosDetailsStateModel): Client | null {
        if (!state.portfolio || !isPortfolio(state.portfolio)) {
            return null;
        }

        return state.portfolio.client ?? null;
    }

    @Selector()
    static getPortfolioLastAllocation(state: PortfoliosDetailsStateModel): AllocationToUpdate | null {
        if (!state.portfolio || !isPortfolio(state.portfolio)) {
            return null;
        }

        return state.portfolio.allocation ?? null;
    }

    @Selector()
    static getPortfolioLastAllocationValuation(state: PortfoliosDetailsStateModel): Allocation | null {
        if (!state.portfolio || !isPortfolio(state.portfolio)) {
            return null;
        }

        return state.portfolio.lastAllocationValuation ?? null;
    }

    @Selector()
    static getPortfolioType(state: PortfoliosDetailsStateModel): PortfolioType | undefined {
        return state.portfolio?.type.id;
    }

    @Selector()
    static getPortfolioAlerts(state: PortfoliosDetailsStateModel): PortfolioAlert[] {
        if (!state.portfolio || !isPortfolio(state.portfolio)) {
            return [];
        }
        return state.portfolio.alerts || [];
    }

    @Selector()
    static getPortfolioAlertsCount(state: PortfoliosDetailsStateModel): number {
        if (!state.portfolio || !isPortfolio(state.portfolio)) {
            return 0;
        }
        return (state.portfolio.alerts || []).length;
    }

    @Selector()
    static getPortfolioDetailsError(state: PortfoliosDetailsStateModel): ErrorType | undefined {
        return state.error;
    }

    @Selector()
    static getPortfolioCurrency(state: PortfoliosDetailsStateModel): string | undefined {
        return state.portfolio?.currency;
    }


    @Selector()
    static getSharesCategories(state: PortfoliosDetailsStateModel): ShareCategory[] {
        return state.shareCategories.values;
    }

    @Selector()
    static totalNumberOfSharesCategories(state: PortfoliosDetailsStateModel): number {
        return state.shareCategories.totalNumber;
    }

    @Selector()
    static getClients(state: PortfoliosDetailsStateModel): Contact[] {
        return state.clients.values;
    }

    @Selector()
    static getPortfolioDetailsViewModeDisplay(state: PortfoliosDetailsStateModel) {
        return state.viewModeDisplay[state.params.viewMode];
    }

    @Selector()
    static getPortfolioDetailsViewModeParams(state: PortfoliosDetailsStateModel): ViewModeType {
        return state.params.viewMode;
    }

    @Selector()
    static getPortfolioDetailsGlobalParams(state: PortfoliosDetailsStateModel): GlobalParams {
        return state.params.globalParams;
    }

    @Selector()
    static getPortfolioDetailsEndDateParams(state: PortfoliosDetailsStateModel): string {
        return state.params.globalParams.endDate;
    }

    @Selector()
    static getPortfolioDetailsRiskIndicatorsViewModeParams(state: PortfoliosDetailsStateModel): ViewModeType {
        return state.params.riskIndicatorsViewMode;
    }

    @Selector()
    static getPortfolioDetailsCompositionViewModeParams(state: PortfoliosDetailsStateModel): GroupCompositionViewMode {
        return state.params.compositionViewMode;
    }

    @Selector()
    static getTestSrriEnabled(state: PortfoliosDetailsStateModel): boolean {
        return state.params.testSrriEnabled;
    }

    @Selector()
    static getDisplayTestSrri(state: PortfoliosDetailsStateModel): boolean {
        return state.params.displayTestSrri;
    }

    @Selector()
    static getReturnUrl(state: PortfoliosDetailsStateModel): string | null {
        return state.returnUrl;
    }

    @Selector()
    static totalNumberOfClients(state: PortfoliosDetailsStateModel): number {
        return state.clients.totalNumber;
    }

    @Selector()
    static getCurrentPortfolioDetailsModel(state: PortfoliosDetailsStateModel): Portfolio | CreatePortfolio | undefined {
        return state.portfolio;
    }

    @Selector()
    static getAllocationsMap(state: PortfoliosDetailsStateModel): Map<string | number, AllocationGroupPrices> | undefined {
        return state.allocations;
    }

    @Selector()
    static getCurrentPortfolioCreatedDateDetailsModel(state: PortfoliosDetailsStateModel): string | undefined {
        if (state.portfolio && isPortfolio(state.portfolio)) {
            return state.portfolio.createdDate;
        }
        return undefined;
    }

    @Selector([PortfoliosDetailsState.getCurrentPortfolioDetailsModel])
    static getCurrentPortfolioDetailsVideos(state: PortfoliosDetailsStateModel, data: Portfolio): Video[] {
        return data.videos;
    }

    @Selector([PortfoliosDetailsState.getCurrentPortfolioDetailsModel])
    static getCurrentPortfolioDetailsDocuments(state: PortfoliosDetailsStateModel, data: Portfolio): DocumentItem[] {
        return data.documents;
    }

    @Selector()
    static getCurrentAllocationChangeType(state: PortfoliosDetailsStateModel): AllocationChangeType | undefined {
        return state.allocationChangeType;
    }

    @Selector()
    static getCurrentShares(state: PortfoliosDetailsStateModel): Share[] {
        return state.portfolio?.shares || [];
    }

    @Selector()
    static getPermissions(state: PortfoliosDetailsStateModel): { [key: string]: boolean } {
        if ((state.portfolio && !isPortfolio(state.portfolio)) || !state.portfolio || !state.portfolio.permissions) {
            return {
                [PermissionType.READ]: true,
                [PermissionType.EDIT]: false,
                [PermissionType.DELETE]: false,
            };
        }

        return state.portfolio.permissions.reduce((acc, permission) => ({
            ...acc,
            [permission.id]: permission.havePermission,
        }), {});
    }

    @Action(ApplyOperationAction)
    applyOperation({ patchState, getState, dispatch }: StateContext<PortfoliosDetailsStateModel>, action: ApplyOperationAction) {
        const state = getState();

        const { portfolio } = state;

        if (action.operation.type === OperationType.ADD_SHARES_TO_PORTFOLIO && portfolio && isPortfolio(portfolio)) {
            const portfolioCurrentSharesIds = portfolio.allocation.shares.map((item) => item.shareId);

            const selectedShares: Array<{shareId: number, amount: number}> = (action.operation.data as number[])
                .filter((id) => !portfolioCurrentSharesIds.includes(id))
                .map((id) => ({
                    shareId: id,
                    amount: 0,
                    quantity: 0,
                    forex: null,
                    isNewAllocation: true,
                    newAllocationPrices: {
                        price: 0,
                        forex: 1,
                        quantity: 0,
                        weight: 0,
                        amount: 0,
                    },
                    diffAllocationPrices: {
                        price: 0,
                        forex: 1,
                        weight: 0,
                        ...(portfolio.type.id === PortfolioType.REAL ? { amount: 0 } : {}),
                    },
                }));

            patchState({
                portfolioState: PortfoliosState.UPDATING,
                returnUrl: action.operation.returnUrl,
            });

            const cashes = [...portfolio.allocation.cashes];
            const shares = [
                ...portfolio.allocation.shares,
                ...selectedShares,
            ];

            dispatch(new UpdateAllocationInStateAction(cashes, shares));
        }
    }

    @Action(ChangePortfolioStateAction)
    changePortfolioState({ patchState }: StateContext<PortfoliosDetailsStateModel>, action: ChangePortfolioStateAction) {
        patchState({
            portfolioState: action.portfolioState,
        });
    }

    @Action(ResetReturnUrlAction)
    resetReturnUrl({ patchState }: StateContext<PortfoliosDetailsStateModel>) {
        patchState({
            returnUrl: undefined,
        });
    }

    @Action(ChangeCreationUserJourneyAction)
    changeCreationUserJourney(
        { getState, setState, dispatch }: StateContext<PortfoliosDetailsStateModel>,
        action: ChangeCreationUserJourneyAction,
    ) {
        const state = getState();
        let creationType: CreationPortfolioType | null = state.createForm?.creationType ?? null;
        // eslint-disable-next-line default-case
        switch (action.key) {
            case USER_JOURNEY.INITIAL:
                creationType = null;
                break;
            case USER_JOURNEY.FUND_SEARCH:
                creationType = CreationPortfolioType.FUND_SEARCH;
                dispatch(new AddShareListAction(PORTFOLIO_CREATE_SHARE_LIST_KEY, true, PORTFOLIO_CREATE_SHARE_LIST_EXTRA_FILTERS));
                break;
            case USER_JOURNEY.DUPLICATION:
                creationType = CreationPortfolioType.DUPLICATION;
                dispatch(new AddPortfolioListAction(PORTFOLIO_CREATE_PORTFOLIO_LIST_KEY));
                break;
        }

        setState(onChangeCurrentCreateForm({
            userJourney: action.key,
            creationType,
        }));
    }

    @Action(ChangeCreationTypeAction)
    changeCreationType({ setState }: StateContext<PortfoliosDetailsStateModel>, action: ChangeCreationTypeAction) {
        setState(onChangeCurrentCreateForm({
            creationType: action.creationType,
        }));
    }

    @Action(ChangePortfolioTypeAction)
    changePortfolioType({ setState, patchState }: StateContext<PortfoliosDetailsStateModel>, action: ChangePortfolioTypeAction) {
        setState(onChangeCurrentCreateForm({
            portfolioType: action.type,
        }));

        patchState({
            allocationChangeType: action.type === PortfolioType.REAL ? AllocationChangeType.AMOUNT : AllocationChangeType.WEIGHT,
        });
    }

    @Action(ChangePortfolioErrorAction)
    changePortfolioError({ patchState }: StateContext<PortfoliosDetailsStateModel>, action: ChangePortfolioErrorAction) {
        patchState({
            error: action.error,
        });
    }

    @Action(InitPortfolioAction)
    initPortfolio({ patchState }: StateContext<PortfoliosDetailsStateModel>, action: InitPortfolioAction) {
        patchState({
            error: { value: false },
            returnUrl: action.returnUrl,
            portfolio: action.data,
            portfolioState: PortfoliosState.CREATING,
        });
    }

    @Action(ChangePortfolioFieldAction)
    changePortfolioField({ setState }: StateContext<PortfoliosDetailsStateModel>, { key, value }: ChangePortfolioFieldAction) {
        setState(onChangeCurrentPortfolio({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [key]: value,
        }));
    }

    @Action(ChangePortfolioVideosAction)
    changePortfolioVideos({ setState }: StateContext<PortfoliosDetailsStateModel>, { videos }: ChangePortfolioVideosAction) {
        setState(onChangeCurrentPortfolio({
            videos,
        }));
    }

    @Action(ChangePortfolioDocumentsAction)
    changePortfolioDocuments({ setState }: StateContext<PortfoliosDetailsStateModel>, { documents }: ChangePortfolioDocumentsAction) {
        setState(onChangeCurrentPortfolio({
            documents,
        }));
    }

    @Action(GetPortfolioVideosAction)
    getPortfolioVideos({ setState }: StateContext<PortfoliosDetailsStateModel>, action: GetPortfolioVideosAction): Observable<unknown> {
        return this.portfoliosService.getPortfolioVideos(action.portfolioId)
            .pipe(
                tap((videos) => {
                    setState(onChangeCurrentPortfolio({
                        videos: [...videos],
                    }));
                }),
            );
    }

    @Action(DeletePortfolioVideoAction)
    deletePortfolioVideo({ setState, getState }: StateContext<PortfoliosDetailsStateModel>, action: DeletePortfolioVideoAction): Observable<unknown> {
        return this.portfoliosService.deletePortfolioVideo(action.portfolioId, action.videoId)
            .pipe(
                tap(() => {
                    const videos = getState().portfolio?.videos ?? [];
                    const filteredVideos = videos.filter((item) => item.id !== action.videoId);
                    setState(onChangeCurrentPortfolio({
                        videos: filteredVideos,
                    }));
                }),
            );
    }

    @Action(GetPortfolioDocumentsAction)
    getPortfolioDocuments({ setState }: StateContext<PortfoliosDetailsStateModel>, action: GetPortfolioDocumentsAction): Observable<unknown> {
        return this.portfoliosService.getPortfolioDocuments(action.portfolioId)
            .pipe(
                tap((documents) => {
                    setState(onChangeCurrentPortfolio({
                        documents: [...documents],
                    }));
                }),
            );
    }

    @Action(DeletePortfolioDocumentAction)
    deletePortfolioDocument({ setState, getState }: StateContext<PortfoliosDetailsStateModel>, action: DeletePortfolioDocumentAction): Observable<unknown> {
        return this.portfoliosService.deletePortfolioDocument(action.portfolioId, action.documentId)
            .pipe(
                tap(() => {
                    const documents = getState().portfolio?.documents ?? [];
                    const filteredDocuments = documents.filter((item) => item.id !== action.documentId);
                    setState(onChangeCurrentPortfolio({
                        documents: filteredDocuments,
                    }));
                }),
            );
    }

    @Action(GetShareCategoriesAction)
    getShareCategories({ patchState, getState } : StateContext<PortfoliosDetailsStateModel>, { payload }: GetShareCategoriesAction) {
        let currentState = getState();

        const isNewSearch = !!(typeof payload.q === 'string' && currentState.shareCategories.q !== payload.q);

        if (payload.reset) {
            patchState({
                shareCategories: {
                    size: 20,
                    totalNumber: 0,
                    startAt: 0,
                    q: '',
                    values: [],
                },
            });
        } else if (isNewSearch) {
            patchState({
                shareCategories: {
                    size: 20,
                    totalNumber: 0,
                    startAt: 0,
                    q: payload.q || '',
                    values: currentState.shareCategories.values,
                },
            });
        }

        currentState = getState();

        return this.shareDetailsService.getCategoriesSearch({
            startAt: currentState.shareCategories.startAt,
            q: currentState.shareCategories.q,
            size: currentState.shareCategories.size,
        }).pipe(tap((result) => {
            const state = getState();
            patchState({
                shareCategories: {
                    ...state.shareCategories,
                    totalNumber: result.totalNumber,
                    startAt: result.values[result.values.length - 1]?.id || 0,
                    values: isNewSearch
                        ? [...result.values]
                        : [
                            ...(state.shareCategories.values ?? []),
                            ...result.values,
                        ],
                },
            });
        }));
    }

    @Action(GetClientsAction)
    getClients({ patchState, getState } : StateContext<PortfoliosDetailsStateModel>, { payload }: GetClientsAction) {
        let currentState = getState();
        const isNewSearch = !!(typeof payload.q === 'string' && currentState.clients.q !== payload.q);

        if (payload.reset) {
            patchState({
                clients: {
                    size: 20,
                    totalNumber: 0,
                    startAt: 0,
                    requestId: '',
                    q: '',
                    values: [],
                },
            });
        } else if (isNewSearch) {
            patchState({
                clients: {
                    size: 20,
                    totalNumber: 0,
                    startAt: 0,
                    requestId: '',
                    q: payload.q || '',
                    values: currentState.clients.values,
                },
            });
        } else if (currentState.clients.values.length >= currentState.clients.totalNumber) {
            return EMPTY;
        }

        currentState = getState();
        return this.contactsService.search({
            fields: 'id,firstName,lastName,srri,',
            startAt: currentState.clients.startAt,
            q: currentState.clients.q,
            size: currentState.clients.size,
            requestId: currentState.clients.requestId,
            filters: [{
                property: 'permissions',
                values: [PermissionType.ADD_PORTFOLIO],
                exclude: false,
                union: true,
            }],
            sorts: [],
            group: ENVESTBOARD_GROUP_ID,
            subSegmentation: '',
            includeMetadata: false,
        }).pipe(tap((result) => {
            const state = getState();
            patchState({
                clients: {
                    ...state.clients,
                    totalNumber: result.totalNumber,
                    startAt: result.values[result.values.length - 1]?.id || 0,
                    requestId: result.requestId,
                    values: isNewSearch
                        ? [...result.values]
                        : [
                            ...(state.clients.values ?? []),
                            ...result.values,
                        ],
                },
            });
        }));
    }

    @Action(AllocationChangeTypeAction)
    allocationChangeType({ patchState }: StateContext<PortfoliosDetailsStateModel>, action: AllocationChangeTypeAction) {
        patchState({
            allocationChangeType: action.allocationChangeType,
        });
    }

    @Action(GetCurrentForexAction)
    getCurrentForex({ getState, dispatch }: StateContext<PortfoliosDetailsStateModel>) {
        const state = getState();

        if (!state.portfolio) {
            return;
        }

        const { currency } = state.portfolio;
        const forex = this.store.selectSnapshot(ApplicationState.getForex);
        if (!forex[currency]) {
            dispatch(new GetForexAction(currency));
        }
    }

    @Action(GetAllocationSharesAction)
    getAllocationShares({ getState, setState, dispatch }: StateContext<PortfoliosDetailsStateModel>): Observable<unknown> {
        let state = getState();

        if (!state.portfolio) {
            return EMPTY;
        }
        const sharesIds = state.portfolio.allocation.shares
            .map((allocation) => allocation.shareId.toString());

        return this.shareDetailsService.search(getShareSearchParams(sharesIds)).pipe(
            tap((result) => {
                setState(onChangeCurrentPortfolio({
                    shares: result.values,
                }));
            }),
            tap(() => {
                state = getState();
                if (state.portfolioState !== PortfoliosState.CREATING) {
                    dispatch(new GetRiskIndicatorsOfAllocationSharesAction());
                }
            }),
        );
    }

    @Action(GetRiskIndicatorsOfAllocationSharesAction)
    getRiskIndicatorsOfAllocationSharesAction({ getState, setState }: StateContext<PortfoliosDetailsStateModel>): Observable<unknown> {
        let state = getState();

        if (!state.portfolio) {
            return EMPTY;
        }
        const shareIds = state.portfolio.allocation.shares.map((allocation) => allocation.shareId);
        const indicatorsParams = this.indicatorsUtilsService.createIndicatorsForRequests(DISPLAY_INDICATORS_VALUE_FOR_COMPOSITION);

        return forkJoin([
            this.riskIndicatorsService.getRiskIndicatorsValuesForSharesOrCategories(shareIds, [], indicatorsParams.defaultRiskIndicators),
            this.activeRisksService.getActiveRisksValuesForSharesOrCategories(shareIds, [], indicatorsParams.defaultActiveRisks),
            this.notationsService.getNotationsValuesForSharesOrCategories(shareIds, [], indicatorsParams.defaultNotations),
        ]).pipe(
            tap(([resultRiskIndicators, resultActiveRisks, resultNotations]) => {
                state = getState();
                const shares = clone(state.portfolio?.shares as Array<Share>);
                let shareIndex: number;
                resultRiskIndicators.forEach((riskIndicator) => {
                    if (riskIndicator.type === EntityType.SHARE) {
                        shareIndex = shares.findIndex((share) => share.id === riskIndicator.id);
                        if (shareIndex !== -1) {
                            shares[shareIndex].riskIndicators = riskIndicator.riskIndicators;
                        }
                    }
                });

                resultActiveRisks.forEach((activeRisk) => {
                    if (activeRisk.type === EntityType.SHARE) {
                        shareIndex = shares.findIndex((share) => share.id === activeRisk.id);
                        if (shareIndex !== -1) {
                            shares[shareIndex].activeRisks = activeRisk.activeRisks;
                        }
                    }
                });

                resultNotations.forEach((notation) => {
                    if (notation.type === EntityType.SHARE) {
                        shareIndex = shares.findIndex((share) => share.id === notation.id);
                        if (shareIndex !== -1) {
                            shares[shareIndex].notations = notation.notations;
                        }
                    }
                });
                setState(onChangeCurrentPortfolio({
                    shares,
                }));
            }),
        );
    }

    @Action(GetPortfolioAction)
    getPortfolio({ patchState, getState }: StateContext<PortfoliosDetailsStateModel>, action: GetPortfolioAction): Observable<unknown> {
        return this.portfoliosService.getPortfolio(action.portfolioId).pipe(
            tap((portfolio) => {
                const state = getState();

                patchState({
                    portfolio: {
                        ...portfolio,
                        ...(state.portfolio?.allocation ? { allocation: state.portfolio.allocation } : {}),
                    },
                });
            }),
        );
    }

    @Action(InitPortfolioDetailAction)
    initPortfolioDetail({ patchState, dispatch, getState }: StateContext<PortfoliosDetailsStateModel>, action: InitPortfolioDetailAction): Observable<unknown> {
        return this.portfoliosService.getPortfolio(action.portfolioId).pipe(
            tap((portfolio) => {
                const state = getState();

                patchState({
                    params: {
                        ...state.params,
                        testSrriEnabled: true,
                        displayTestSrri: false,
                        globalParams: {
                            ...defaults.params.globalParams,
                            ...(
                                portfolio.createdDate && new Date(portfolio.createdDate) >= new Date(state.params.globalParams.endDate)
                                    ? {
                                        endDate: portfolio.createdDate,
                                    }
                                    : {}
                            ),
                        },
                    },
                    portfolio: {
                        ...portfolio,
                        allocation: portfolio.lastAllocationValuation ? initAllocation(portfolio.lastAllocationValuation, portfolio.type.id) : {
                            shares: [],
                            cashes: [],
                        },
                    },
                    allocationChangeType: portfolio.type.id === PortfolioType.REAL ? AllocationChangeType.AMOUNT : AllocationChangeType.WEIGHT,
                    nextCalculatedSrri: undefined,
                });

                if (action.operation) {
                    dispatch(new ApplyOperationAction(action.operation));
                }
            }),
        );
    }

    @Action(GetPortfolioLastAllocationAction)
    getPortfolioLastAllocationAction(
        { patchState }: StateContext<PortfoliosDetailsStateModel>,
        action: GetPortfolioLastAllocationAction,
    ): Observable<unknown> {
        return this.portfoliosService.getLastAllocation(action.portfolioId)
            .pipe(
                tap((allocation) => {
                    patchState({
                        lastAllocation: { ...allocation },
                    });
                }),
            );
    }

    @Action(UpdateAllocationInStateAction)
    updateAllocationInState({ setState, getState }: StateContext<PortfoliosDetailsStateModel>, action: UpdateAllocationInStateAction) {
        setState(onChangeCurrentPortfolio({
            allocation: {
                shares: action.shareAllocations,
                cashes: action.cashAllocations,
            },
        }));

        const state = getState();
        setState({
            ...state,
            params: {
                ...state.params,
                testSrriEnabled: true,
            },
        });
    }

    @Action(UpdateAllocationsMap)
    updateAllocationsMap({ patchState }: StateContext<PortfoliosDetailsStateModel>, action: UpdateAllocationsMap) {
        patchState({
            allocations: action.allocations,
        });
    }

    @Action(CreateCurrentPortfolioAction)
    createCurrentPortfolio({ getState, dispatch }: StateContext<PortfoliosDetailsStateModel>) {
        const state = getState();

        if (!state.portfolio || isPortfolio(state.portfolio)) {
            return EMPTY;
        }
        return this.portfoliosService.createPortfolio(state.portfolio)
            .pipe(
                tap((createdPortfolio) => {
                    dispatch(new CreateCurrentPortfolioSuccessAction(createdPortfolio));
                }),
            );
    }

    @Action(UpdateAllocationAction)
    updateAllocation({ getState, setState, patchState, dispatch }: StateContext<PortfoliosDetailsStateModel>) {
        const state = getState();

        if (!state.portfolio?.allocation || !isPortfolio(state.portfolio)) {
            return EMPTY;
        }
        const allocationParams = updateAllocationForRequest(state.portfolio.allocation, state.portfolio.type.id);
        return this.portfoliosService.updateAllocation(state.portfolio.id, allocationParams)
            .pipe(
                tap((allocation) => {
                    setState(onChangeCurrentPortfolio({
                        allocation: initAllocation(allocation, getState().portfolio?.type.id ?? PortfolioType.REAL),
                    }));
                    patchState({
                        returnUrl: null,
                    });
                    dispatch(new ChangePortfolioStateAction(PortfoliosState.SHOW));
                }),
            );
    }

    @Action(ReinitAllocationAction)
    reinitAllocation({ setState }: StateContext<PortfoliosDetailsStateModel>, action: ReinitAllocationAction): Observable<unknown> {
        return this.portfoliosService.getPortfolio(action.portfolioId).pipe(
            tap((portfolio) => {
                if (portfolio.lastAllocationValuation) {
                    setState(onChangeCurrentPortfolio({
                        allocation: initAllocation(portfolio.lastAllocationValuation, portfolio?.type.id ?? PortfolioType.REAL),
                    }));
                }
            }),
        );
    }

    @Action(PostAlertAction)
    postAlertAction({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>, { action }: PostAlertAction) {
        return this.alertsService.postAlertAction(action)
            .pipe(
                tap(() => {
                    const state = getState();

                    if (!state.portfolio || !isPortfolio(state.portfolio)) {
                        return;
                    }

                    patchState({
                        portfolio: {
                            ...state.portfolio,
                            alerts: state.portfolio.alerts?.filter((alert) => alert.id !== action.alertId),
                        },
                    });
                }),
            );
    }

    @Action(UpdatePortfolioAction)
    updatePortfolio({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>, action: UpdatePortfolioAction) {
        const state = getState();

        if (!state.portfolio?.allocation || !isPortfolio(state.portfolio)) {
            return EMPTY;
        }
        return this.portfoliosService.updatePortfolio(state.portfolio.id, action.fieldsChanged)
            .pipe(
                tap((portfolio) => {
                    patchState({
                        portfolio: {
                            ...state.portfolio,
                            ...portfolio,
                        },
                    });
                }),
            );
    }

    @Action(ResetAction)
    reset({ setState }: StateContext<PortfoliosDetailsStateModel>) {
        setState({
            ...defaults,
        });
    }

    @Action(ChangeViewModeAction)
    changeViewMode({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>, action: ChangeViewModeAction) {
        const state = getState();
        patchState({
            params: {
                ...state.params,
                viewMode: action.viewMode,
            },
        });
    }

    @Action(UpdateGlobalParams)
    updateGlobalParams({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>,
        { period, endDate }: UpdateGlobalParams) {
        const state = getState();

        patchState({
            params: {
                ...state.params,
                globalParams: {
                    period,
                    endDate,
                },
            },
        });
    }

    @Action(UpdateRiskIndicatorsViewModeParams)
    updateRiskIndicatorsViewModeParams({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>,
        { riskIndicatorsViewMode }: UpdateRiskIndicatorsViewModeParams) {
        const state = getState();

        patchState({
            params: {
                ...state.params,
                riskIndicatorsViewMode,
            },
        });
    }

    @Action(UpdateCompositionViewModeParams)
    UpdateCompositionViewModeParams({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>,
        { compositionViewMode }: UpdateCompositionViewModeParams) {
        const state = getState();

        patchState({
            params: {
                ...state.params,
                compositionViewMode,
            },
        });
    }

    @Action(ResetViewModeDisplayAction)
    resetViewModeDisplay({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>) {
        const state = getState();

        const currentViewMode = state.params.viewMode;

        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                ...(
                    currentViewMode === ViewModeType.PRO
                        ? {
                            PRO: PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES.PRO,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.ADVANCED
                        ? {
                            ADVANCED: PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.MAIN
                        ? {
                            MAIN: PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES.MAIN,
                        }
                        : {}
                ),
            },
        });
    }

    @Action(SetViewModeDisplayValuesAction)
    setViewModeDisplayValuesAction({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>, action: SetViewModeDisplayValuesAction) {
        const state = getState();
        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                [state.params.viewMode]: action.display,
            },
        });
    }

    @Action(TestSRRIAction)
    testSRRI({ getState, setState }: StateContext<PortfoliosDetailsStateModel>, action: TestSRRIAction): Observable<unknown> {
        let state = getState();
        setState({
            ...state,
            params: {
                ...state.params,
                testSrriEnabled: false,
            },
        });

        return this.srrisService.getSrris(action.allocation, false)
            .pipe(
                tap((calculatedSrri) => {
                    state = getState();
                    setState({
                        ...state,
                        nextCalculatedSrri: calculatedSrri,
                        params: {
                            ...state.params,
                            testSrriEnabled: false,
                            displayTestSrri: true,
                        },
                    });
                }),
            );
    }

    @Action(EnableTestSRRIAction)
    enableTestSRRI({ getState, patchState }: StateContext<PortfoliosDetailsStateModel>) {
        const state = getState();
        patchState({
            params: {
                ...state.params,
                testSrriEnabled: true,
            },
        });
    }

    @Action(RefreshCurrentCalculatedSRRIAction)
    refreshCurrentCalculatedSRRI({ getState, setState }: StateContext<PortfoliosDetailsStateModel>): Observable<unknown> {
        const state = getState();

        const { portfolio } = state;

        if (!portfolio || !isPortfolio(portfolio)) {
            return EMPTY;
        }

        return this.portfoliosService.getPortfolio(portfolio.id).pipe(
            tap((data) => {
                setState(onChangeCurrentPortfolio({
                    calculatedSrri: data.calculatedSrri,
                }));
            }),
        );
    }
}
